import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertController } from '@ionic/angular';
import { User } from 'src/app/class/user';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-ver-usuarios',
  templateUrl: './ver-usuarios.component.html',
  styleUrls: ['./ver-usuarios.component.css']
})
export class VerUsuariosComponent implements OnInit {


  form!: FormGroup;
  usuarios : User[] = [];
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService,
    private alertController: AlertController,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.rescatarUserForm();
    this.getusuarios();

  }
  rescatarUserForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      apellido : ['',[Validators.required]],
      email : ['',[Validators.required]],



    });
  }
  getusuarios() {
    this.votacionService.VerUsuariosClaves().subscribe(res =>{

      console.log(res)
      this.usuarios=res
    },
    err =>
    console.log(err)
    )
  }


  eliminar(id:number){
    const id2: string = this.activatedRoute.snapshot.params['id'];
    this.votacionService.eliminarUsuario(id).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/verVotacion'])
    })
  }

  editar(id:number){

    this.votacionService.editarUsuario(id,this.form.value).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/verVotacion'])
    })
  }

}
