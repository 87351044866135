import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ExporterService } from 'src/app/services/exporter.service';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-crear-usuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.css']
})
export class CrearUsuarioComponent implements OnInit {


  form!: FormGroup;
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService,
    private exporterService: ExporterService) { }

  ngOnInit(): void {
    this.rescataUsuarioForm();
  }
  rescataUsuarioForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      apellido : ['',[Validators.required]],
      email: ['', [Validators.required]],
      telefono: ['',[Validators.required]],
      rol: ['',[Validators.required]],



    });
  }

  submitUsuarioForm(){
    this.votacionService.crearMFYcE(this.form.value).subscribe(
      res =>{
        console.log(res);
        this.router.navigate(['/'])

      },
      err => console.log(err)
    );
  }

}
