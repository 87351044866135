
<table class="table">
  <thead>
    <tr>

      <th scope="col">Validar Rut</th>
      <th scope="col">Validar genero</th>
      <th scope="col">Validar Repetidos</th>
      <th scope="col">Validar Email</th>
    </tr>
  </thead>
  <tbody>
    <tr >
      <td>
          <input type="file"  name="file" (change)="validarPadronRuts($event)"   class="form-control"  placeholder="file" >
          <label for="floatingInput">Padron</label></td>

              <td>   <input type="file"  name="file" (change)="validarPadronGenero($event)"   class="form-control"  placeholder="file" >
                </td>
                <td>   <input type="file"  name="file" (change)="validarPadronRepetidos($event)"   class="form-control"  placeholder="file" >
                </td>

                <td>   <input type="file"  name="file" (change)="validarPadronEmail($event)"   class="form-control"  placeholder="file" >
                </td>
    </tr>

  </tbody>
</table>

