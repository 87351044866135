import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { jsPDF } from "jspdf";
import { Categoria } from 'src/app/class/categoria';
import { Opcion } from 'src/app/class/opcion';
import { Resultado } from 'src/app/class/resultado';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import autoTable from 'jspdf-autotable';
import { User } from 'src/app/class/user';
import { ExporterService } from 'src/app/services/exporter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpcionResultado } from 'src/app/class/opcionResultado';
import { userPDF } from 'src/app/class/user-pdf';
import { userPDF2 } from 'src/app/class/user-pdf2';
@Component({
  selector: 'app-ver-resultado-region',
  templateUrl: './ver-resultado-region.component.html',
  styleUrls: ['./ver-resultado-region.component.css']
})
export class VerResultadoRegionComponent implements OnInit {

  resultado : Resultado = {
    id: 0,
    emitidosHombre: 0,
    emitidosMujer: 0,
    noEmitidosHombre: 0,
    noEmitidosMujer : 0,
    porcentajeEmitidosHombre: 0,
    porcentajeEmitidosMujer: 0,
    porcentajenoEmitidosHombre: 0,
    porcentajenoEmitidosMujer: 0,
    porcentajepreferenciaBlanco: 0,
    porcentajepreferenciaNulas: 0,
    porcentajepreferenciasNormal: 0,
    porcentajepreferenciasNoUtilizadaNoVotante: 0,
    porcentajeTotal:0,
    porcentajeTotalEmitidos: 0,
    porcentajetotalHombreIncritas:0,
    porcentajetotalMujeresIncritas: 0,
    porcentajeTotalNoEmitido: 0,
    porcentajetotalPrefereciasPosibles: 0,
    preferenciaBlanco: 0,
    preferenciaNulas: 0,
    preferenciasNormal: 0,
    preferenciasNoUtilizadaNoVotante: 0,
    totalEmitidos:0 ,
    totalHombreIncritos: 0,
    totalIncritos: 0,
    totalMujeresIncritas: 0,
    totalNoEmitidos: 0,
    totalPrefereciasPosibles:0
  }
  userPDF : Array<userPDF> = [];
  userPDF2 : Array<userPDF2> = [];
  userPDF3 : Array<userPDF> = [];

  votacion : Votacion = {
    id : 0,
    descripcion : '',
    tipo:'',
    fechaApertura: new Date(),
    fechaCierre: new Date(),
    imagen: '',
    maximoOpciones : 0,
    nombre: '',
    participacionHombre : 0,
    participacionMujer : 0 ,
    porcentajeParticipacion : 0,
    totalIncritos : 0,
    totalIncritosHombre : 0 ,
    totalIncritosMujer : 0,
    votosHombre : 0 ,
    votosMujer : 0,
    votosRecibidos : 0,
    estado: ''


  }

  categoria1 : Categoria = {
    id:0,
    detalle: '',
    texto: ''

  }
  opciones : Opcion[] = [];

  opciones2 : OpcionResultado[] = [];
  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };
      myDataArray : User[]=[];
      myDataArray2 : User[]=[];
      myDataArray3 : User[]=[];
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService,
    private exporterService: ExporterService
  ) { }

  ngOnInit(): void {
    const id: number = this.activatedRoute.snapshot.params['id'];
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    console.log('id que traigo',id);
    this.getOpciones(id,grupo);
    this.getVotacion(id);
    this.categoria(id);
    this.getResultados(id,grupo);
    this.inscritosPDF(id);
    this.votantesPDF(id);
    this.novotantesPDF(id);

    this.getUsers();

  }

  getUsers() {
    this.votacionService.ministro().subscribe(
      res => {
      if(res!=null){
      this.user=res
      }
      else{
       this.user.nombre='fairvoting'
       this.user.apellido='';
      }
    }
    ,
      err=> console.log(err)
    )
  }
  inscritosPDF(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.incritosRegion(id,grupo).subscribe(
      res => {this.userPDF=res




      },
      err=> console.log(err)
    )
    this.userPDF.length=0;
  }

  votantesPDF(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.votantesRegion(id,grupo).subscribe(
      res => {this.userPDF2=res




      },
      err=> console.log(err)
    )
    this.userPDF2.length=0;
  }

  novotantesPDF(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.novotantesRegion(id,grupo).subscribe(
      res => {this.userPDF3=res




      },
      err=> console.log(err)
    )
    this.userPDF3.length=0;
  }

  ImprimirincritosPDF(id:number){

    const grupo: string = this.activatedRoute.snapshot.params['grupo'];

        const doc2 = new jsPDF();
  doc2.text("Socios inscritos de : "+this.votacion.nombre,40,20);
    var logo = new Image();
logo.src = '../../../assets/images/VPTING_-removebg.png';


doc2.addImage(logo, 'JPEG', 5, 5, 30, 30);

autoTable(doc2, { html: '#incritospdf',margin:40,theme:'grid',styles: { font : 'helvetica' ,
fontStyle: 'bold',halign : 'center'}})

    doc2.save('socios_inscritos_'+grupo+'-'+this.votacion.nombre+'.pdf');
  }

  ImprimirVotantesPDF(id:number){

    const grupo: string = this.activatedRoute.snapshot.params['grupo'];

        const doc2 = new jsPDF();
  doc2.text("Socios Votantes de : "+this.votacion.nombre,40,20);
    var logo = new Image();
logo.src = '../../../assets/images/VPTING_-removebg.png';


doc2.addImage(logo, 'JPEG', 5, 5, 30, 30);

autoTable(doc2, { html: '#votantespdf',margin:40,theme:'grid',styles: { font : 'helvetica' ,
fontStyle: 'bold',halign : 'center'}})

    doc2.save('socios_votantes_'+grupo+'-'+this.votacion.nombre+'.pdf');
  }

  ImprimirNoVotantesPDF(id:number){

    const grupo: string = this.activatedRoute.snapshot.params['grupo'];

    const doc2 = new jsPDF();
doc2.text("Socios Votantes de : "+this.votacion.nombre,40,20);
var logo = new Image();
logo.src = '../../../assets/images/VPTING_-removebg.png';


doc2.addImage(logo, 'JPEG', 5, 5, 30, 30);

autoTable(doc2, { html: '#novotantespdf',margin:40,theme:'grid',styles: { font : 'helvetica' ,
fontStyle: 'bold',halign : 'center'}})

doc2.save('socios_no_votantes_'+grupo+'-'+this.votacion.nombre+'.pdf');
}

  getOpciones(id: number,grupo:string) {

    this.votacionService.OpcionResultadoRegion(id,grupo).subscribe(
      res =>{ this.opciones2=res;
console.log('ocpiones'+res);


      }
      ,
      err=> console.log(err)
    )
  }
  categoria(id: number) {
    this.votacionService.getCategoria(id).subscribe(
      res =>{ this.categoria1=res;

        console.log('categoria id',this.categoria1.id);



      }
      ,
      err=> console.log(err)
    )
  }
  getVotacion(id: number) {
    this.votacionService.verVotacion(id).subscribe(
      res =>{ this.votacion=res;
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }

  generarExcel(){
    this.votacionService.excel().subscribe(
      res =>{
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }


  incritos(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.incritosRegion(id,grupo).subscribe(
      res => {this.myDataArray3=res
        this.exporterService.exportToExcel(this.myDataArray3,'incritos_'+this.votacion.nombre+'_'+grupo)
        this.myDataArray3.length=0;
      },
      err=> console.log(err)
    )
  }

  votantes(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.votantesRegion(id,grupo).subscribe(
      res => {this.myDataArray2=res
        this.exporterService.exportToExcel(this.myDataArray2,'votantes_'+this.votacion.nombre+'_'+grupo)
        this.myDataArray2.length=0;
      },
      err=> console.log(err)
    )
  }
  round2(valor:number){
    return Math.round(valor);
  }

  round(valor:number){
    return valor.toFixed(3);
  }

  novotante(id:number){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    this.votacionService.novotantesRegion(id,grupo).subscribe(
      res => {this.myDataArray=res
        this.exporterService.exportToExcel(this.myDataArray,'no_votantes_'+this.votacion.nombre+'_'+grupo)
        this.myDataArray.length=0;
      },
      err=> console.log(err)
    )
  }

  generarPDF(){
    const grupo: string = this.activatedRoute.snapshot.params['grupo'];
    const doc = new jsPDF();
  doc.text("Escrutinio : "+this.votacion.nombre+ ' de ' + grupo,40,20);
    var logo = new Image();
logo.src = '../../../assets/images/VPTING_-removebg.png';

doc.addImage(logo, 'JPEG', 5, 5, 30, 30);
    //const head = [['Opcion', 'Votos']]


        autoTable(doc, { html: '#votaciontodo',margin:40,theme:'grid',styles: { font : 'helvetica' ,
      fontStyle: 'bold',halign : 'center'}})
    autoTable(doc, { html: '#tableopciones',theme:'grid',styles: { font : 'helvetica' ,
    fontStyle: 'bold' ,halign : 'center'} })
    doc.addPage();
    autoTable(doc, { html: '#contadorpersonas',theme:'grid',styles: { font : 'helvetica' ,
    fontStyle: 'bold' ,halign : 'center'} })
     autoTable(doc,{html : '#porcentajepersonas',theme:'grid' ,styles: { font : 'helvetica' ,
     fontStyle: 'bold' ,halign : 'center'}})


     autoTable(doc,{html : '#preferencias',theme:'grid' ,styles: { font : 'helvetica' ,
     fontStyle: 'bold' ,halign : 'center'}})
     autoTable(doc,{html : '#porcentajePreferencias',theme:'grid' ,styles: { font : 'helvetica' ,
     fontStyle: 'bold' ,halign : 'center'}})

     var firma = new Image();
     firma.src = '../../../assets/images/Signature.png';


doc.addImage(firma, 'JPEG', 80, 180, 80, 60);

doc.text("Firma del representante legal: Luis Bravo",50,230);
    doc.save('reporte_escrutinio_'+this.votacion.nombre+'.pdf');
  }

  getResultados(id: number,grupo:string) {
    this.votacionService.getResultadoRegion(id,grupo).subscribe(
      res =>{ this.resultado=res;
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }

}
