
<div class="container-fluid">
  <mat-tab-group align="center"  style="text-transform:uppercase">
    <div class="row" align="center"  >

      <mat-tab  label="Resumen Papeleta:  {{ item.nombre }}" *ngFor="let item of votacion">

        <mat-card align="center">
          <mat-card-content>
        <mat-list  style="text-transform:uppercase">
          <mat-list-item>{{ item.nombre }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>{{item.descripcion}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Fecha Inicio: {{ item.fechaApertura | date:'yyyy-MM-dd HH:mm:ss' }}

          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            Fecha Termino:
            {{ item.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Nº máximo de preferencias: {{item.maximoOpciones}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Total inscritos: {{item.totalIncritos}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>  Inscritos Hombres : {{item.totalIncritosHombre==null ? '0' : item.totalIncritosHombre}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Inscritos Mujeres: {{item.totalIncritosMujer ==null ? '0' : item.totalIncritosMujer }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item  style="text-transform:uppercase">{{item.estado+ ' - ' + estado}}</mat-list-item>
          <mat-divider></mat-divider>
         </mat-list>

        </mat-card-content>
      </mat-card>











  <br>

  <br>
  <br>
    </mat-tab>

  </div>
</mat-tab-group>

  <mat-divider></mat-divider>
<br>
<br>
<br>

<table  class="table table-bordered border-primary">
  <thead>

      <th >Nombre</th>
      <th >Correo</th>
      <th >Rol</th>
    </thead>
    <tbody>
      <tr *ngFor="let item2 of user ">

          <td>{{item2.nombre + ' ' + item2.apellido}}</td>
          <td>{{item2.email}}</td>
          <td *ngIf="item2.rol == 'ministro'">Ministro de fe</td>
          <td *ngIf="item2.rol == 'dirigente'">Comisión electoral</td>

        </tr>
    </tbody>
</table>
  <mat-card *ngIf="this.config.estado == 'preparada'">


      <button aria-disabled="true" mat-raised-button style="background-color: #87CEEB;" (click)="aperturar()">Aperturar y generar credenciales</button>
  </mat-card>

  <mat-card *ngIf="this.config.estado == 'autorizada'">


  <h4>Proceso aperturado por ministro de fe</h4>
</mat-card>



</div>

<div style="display: none;">
  <table id="estadistica" >
    <thead>
      <tr>
        <th >Nombre Votación</th>
        <th >Sindicato</th>
        <th >Fecha de inicio</th>
        <th >Fecha de término</th>
        <th >Fecha de apertura</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item2 of votacion ">

            <td>{{item2.nombre}}</td>
            <td>{{item2.descripcion}}</td>
            <td>{{item2.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>{{item2.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>{{myDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
          </tr>
      </tbody>
  </table>
  <table id="datos" >
    <thead>
  <tr>
        <th >Nombre</th>
        <th >Correo</th>
        <th >Rol</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item2 of user ">

            <td>{{item2.nombre + ' ' + item2.apellido}}</td>
            <td>{{item2.email}}</td>
            <td *ngIf="item2.rol == 'ministro'">Ministro de fe</td>
            <td *ngIf="item2.rol == 'dirigente'">Comisión electoral</td>

          </tr>
      </tbody>
  </table>
</div>

