import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-agregar-configuracion',
  templateUrl: './agregar-configuracion.component.html',
  styleUrls: ['./agregar-configuracion.component.css']
})
export class AgregarConfiguracionComponent implements OnInit {
  form!: FormGroup;


  fechaApertura = new Date();
  fechaCierre = new Date();
    constructor(
      private formBuilder : FormBuilder,
      private http: HttpClient,

      private activatedRoute: ActivatedRoute,
      private router : Router,
      private votacionService: VotacionService
    ) { }

    ngOnInit(): void {

      this.rescataVotacionForm();
    }
    rescataVotacionForm() {
      this.form = this.formBuilder.group({
        estado:['', [Validators.required]],
        fechaApertura: ['', [Validators.required]],
        fechaCierre: ['',[Validators.required]],
        tripleFactor: ['',[Validators.required]],



      });
    }
    crearConfig() {

      if (this.form.valid){


      this.votacionService.agregarConfiguracion(this.form.value).subscribe(
        res =>{
          console.log(res);
          this.router.navigate(['/'],{
            skipLocationChange: true})

        },
        err => console.log(err)
      );




    }

}
}


