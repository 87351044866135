
<br>
<br>
<h3 align="center">Validar votación o votaciones en la que esta inscrito</h3>
    <div align="center" class="mb-3">
      <mat-form-field class="login-form">
        <mat-label>Rut</mat-label>
      <input type="text"[(ngModel)]='rut' matInput name="rut" id="rut" oninput="checkRut(this)" onkeypress="return String.fromCharCode(event.charCode).match(/[^a-zA-Z0-9]/g) === null" size="10" maxlength="10" placeholder="Ingrese rut sin puntos ni guion" aria-describedby="emailHelp">
    </mat-form-field>
    <button type="submit" mat-raised-button style="background-color: #87CEEB;" (click)="validar()"><img src="/assets/images/consulta.png" alt="image">&nbsp;&nbsp;CONSULTAR&nbsp;&nbsp;</button>
    </div>



    <br>
    <br>
<div *ngIf="votaciones.length>0"  align="center">
    <table class="table" style="width: 60%;" >
        <thead>
          <tr>
            <th scope="col">Votaciones Inscrito</th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ex of votaciones">
            <td>{{ex.nombre}}</td>
          </tr>
        </tbody>
      </table>
    </div>
      <br>
      <br>

