<div style="text-align:center ;">
  <form [formGroup]="form">
      <br>
      <h3>Crear Categoria</h3>
      <br>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Pregunta</label>
        <input type="text" formControlName="texto"  name="texto" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

      </div>
      <div class="mb-3">
        <label for="exampleInputPassword1" class="form-label">Nombre Sindicato</label>
        <input type="text" formControlName="detalle"  name="detalle" class="form-control" id="exampleInputPassword1">
      </div>


      <button type="submit" (click)="submitCategoriaForm()" class="btn btn-primary">Guardar</button>
    </form>

  </div>
