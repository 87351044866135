<form [formGroup]="form">
  <table class="table">
      <thead>
        <tr>
          <th scope="col">Nombre </th>
          <th scope="col">Apellido </th>
          <th scope="col">Usuario</th>
          <th scope="col">Contraseña</th>
          <th scope="col">Rol Usuario</th>
          <th scope="col">Correo</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ex of usuarios" >
          <td><input type="text"  formControlName="nombre"   name="nombre"    class="form-control"  />
          <span>{{ex.nombre}}</span>
          </td>
          <td><input type="text" formControlName="apellido"   name="apellido"    class="form-control"/>
              <span>{{ex.apellido}}</span>
          </td>
         <td>{{ex.username}}</td>
         <td>{{ex.password}}</td>
         <td>{{ex.rol}}</td>
         <td><input type="email" formControlName="email"  name="email"      class="form-control"/>
          <span>{{ex.email}}</span>
      </td>
         <td>

          <button   class="w-100 btn btn-lg btn-primary" (click)="eliminar(ex.id)"   type="submit">Eliminar Usuario</button>
          <br>
          <br>
          <button   class="w-100 btn btn-lg btn-primary" (click)="editar(ex.id)"   type="submit">Editar Usuario</button>
         </td>


        </tr>


      </tbody>
    </table>
  </form>
