
<div  style="text-align: center;">
  <div style="text-align: center;" class="btn-group flex-wrap" role="group">
  <button (click)="incritos(votacion.id)" mat-raised-button   style="margin: 10px;background-color: #87CEEB;"
  ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos XLS</button>



  <button (click)="votantes(votacion.id)" mat-raised-button  style="margin: 10px;background-color: #87CEEB;"
        ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos votantes XLS </button> <button mat-raised-button   style="margin: 10px;background-color: #87CEEB;"  (click)="novotante(votacion.id)"
           ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos no votantes XLS</button>


  <button mat-raised-button  style="margin: 10px;background-color: #87CEEB;" (click)="generarPDF()"><span
  class="glyphicon glyphicon-list-alt"></span>Imprimir reporte escrutinio</button>

  <button mat-raised-button  style="margin: 10px;background-color: #87CEEB;" (click)="generarPDFApertura()"><span
    class="glyphicon glyphicon-list-alt"></span>Acta de apertura</button>
  </div>

  <div style="text-align: center;"  class="btn-group flex-wrap" role="group">
  <button (click)="ImprimirincritosPDF(votacion.id)" mat-raised-button   style="margin: 10px;background-color: #87CEEB;"
  ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos PDF</button>





  <button (click)="ImprimirVotantesPDF(votacion.id)" mat-raised-button  style="margin: 10px;background-color: #87CEEB;"
        ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos votantes PDF </button> <button mat-raised-button   style="margin: 10px;background-color: #87CEEB;"  (click)="ImprimirNoVotantesPDF(votacion.id)"
           ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos no votantes PDF</button>


  </div>

  <br>
  <br>
  <div style="text-align: center;" class="btn-group flex-wrap" role="group">

  <input type="file"  name="file" (change)="uploadDocumentos($event)"   class="form-control"  placeholder="file2"  >
  <br/>
  <br/>
  <label for="floatingInput">Cargar Masiva PDF</label>
  <br/>
  <br/>
  <button (click)="EnviarDocumentos()" mat-raised-button  style="margin: 10px;background-color: #87CEEB;"
  ><span
  class="glyphicon glyphicon-list-alt"></span> Enviar reportes </button>
  </div>

  </div>
  <br>
  <br>
  <div style="display: none;">
  <table id="estadistica" >
  <thead>
  <tr>
    <th >Nombre Votación</th>
    <th >Sindicato</th>
    <th >Fecha de inicio</th>
    <th >Fecha de término</th>
    <th >Fecha de apertura</th>
  </tr>
  </thead>
  <tbody>
    <tr  >

        <td>{{votacion.nombre}}</td>
        <td>{{votacion.descripcion}}</td>
        <td>{{votacion.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>{{votacion.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>{{votacion.tipo | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      </tr>
  </tbody>
  </table>
  </div>
  <table id="incritospdf" style="display: none;" >



  <thead>


  <tr>


  <th><label>Nombre</label></th>
  <th><label>Apellido</label></th>
  <th><label>Rut</label></th>
  <th><label>Genero</label></th>


  </tr>

  </thead>

  <tr *ngFor="let ex of userPDF">


  <td><label>{{ex.nombre}}</label></td>
  <td><label>{{ex.apellido}}</label></td>
  <td><label>{{ex.rut}}</label></td>
  <td><label>{{ex.genero}}</label></td>



  </tr>


  </table>

  <table id="votantespdf" style="display: none;" >



  <thead>


  <tr>


  <th><label>Nombre</label></th>
  <th><label>Apellido</label></th>
  <th><label>Rut</label></th>
  <th><label>Genero</label></th>
  <th><label>Fecha Voto</label></th>


  </tr>

  </thead>

  <tr *ngFor="let ex of userPDF2">


  <td><label>{{ex.nombre}}</label></td>
  <td><label>{{ex.apellido}}</label></td>
  <td><label>{{ex.rut}}</label></td>
  <td><label>{{ex.genero}}</label></td>
  <td><label>{{ex.fechaVoto}}</label></td>
  



  </tr>


  </table>

  <table id="novotantespdf"  style="display: none;">



  <thead>


  <tr>


  <th><label>Nombre</label></th>
  <th><label>Apellido</label></th>
  <th><label>Rut</label></th>
  <th><label>Genero</label></th>


  </tr>

  </thead>

  <tr *ngFor="let ex of userPDF3">


  <td><label>{{ex.nombre}}</label></td>
  <td><label>{{ex.apellido}}</label></td>
  <td><label>{{ex.rut}}</label></td>
  <td><label>{{ex.genero}}</label></td>



  </tr>


  </table>

      <br/>
      <br/>

      <table id="votaciontodo"  class="table table-bordered border-primary" style="text-align: center;"  >



        <thead>
            <tr>

                <th><label>Datos</label></th>
                <th><label>Resultado</label></th>

            </tr>

            </thead>

            <tbody>
        <tr>
            <td><label for=""><strong>Votacion</strong></label></td>
            <td><strong><label>{{votacion.nombre}}</label></strong></td>
        </tr>
        <tr>
            <td><label for=""><strong>Cliente</strong></label></td>
            <td><strong><label
                    >{{votacion.descripcion}}</label></strong></td>
        </tr>
        <tr>
            <td><label for=""><strong>Inicio de la Votación</strong></label></td>
            <td><strong><label
                    >{{votacion.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</label></strong></td>
        </tr>
        <tr>
            <td><label for=""><strong>Término de la Votación</strong></label></td>
            <td><strong><label
                    >{{votacion.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</label></strong></td>
        </tr>

        <tr>
            <td><label for=""><strong>Maximo de opciones a marcar en voto</strong></label></td>
            <td><strong><label
                    >{{votacion.maximoOpciones}}</label></strong></td>
        </tr>

      <tr >
            <td ><label for=""><strong>Ministro de Fe</strong></label></td>
            <td><strong><label>{{user.nombre +' ' + user.apellido}}</label></strong></td>
        </tr>

        <tr >
            <td ><label for=""><strong>Consulta</strong></label></td>
            <td><strong><label>{{categoria1.texto}}</label></strong></td>
        </tr>

      </tbody>

    </table>
    <br/>
    <br/>
      <ion-card>

                   <table id="tableopciones"
                   class="table table-bordered border-primary" style="text-align: center;">



                          <thead>


                          <tr>


                              <th><label>Opción</label></th>
                              <th><label>Votos</label></th>
                              <th><label>% Votos Recibidos</label></th>
                              <th><label>% Total Incritos y No Usadas</label></th>

                          </tr>

                          </thead>
                          <tbody>
                          <tr *ngFor="let ex of opciones2">


                              <td><label><strong>{{ex.nombreOpcion}}</strong></label></td>

                              <td><label ><strong>{{ex.cantidadOpcion + ' Votos'}}</strong> </label></td>
                              <td><label ><strong>{{round((ex.cantidadOpcion / resultado.preferenciasNormal)*100) + '%' }}</strong>  </label></td>
                              <td><label ><strong> {{round((ex.cantidadOpcion / resultado.totalPrefereciasPosibles)*100) + '%' }}</strong> </label></td>
                          </tr>
                          <tr>
                              <td><label><strong>Total Opciones Validas: </strong></label> </td>
                              <td> <label><strong>{{resultado.preferenciasNormal + ' Preferencias'}}</strong>  </label> </td>
                              <td> <label><strong>100%</strong></label> </td>
                              <td> <label><strong>Total No Usadas {{round(((resultado.preferenciasNoUtilizadaNoVotante+resultado.preferenciaBlanco+resultado.preferenciaNulas) / resultado.totalPrefereciasPosibles)*100 )+ '%' }}</strong> </label> </td>
                          </tr>
                        </tbody>
                      </table>


      </ion-card>

      <br>
      <br>

      <ion-card>
          <ion-card-header>

            </ion-card-header>
            <ion-card-content>
                  <table id="contadorpersonas"   class="table table-bordered border-primary" style="text-align: center;">
                      <thead>
                          <tr >



                              <th colspan="4"><label>Contadores de Votos </label></th>

                            </tr>

                      <tr>
                          <th colspan="1"><label>Genero</label></th>
                          <th colspan="1"><label> Emitidos</label></th>
                          <th colspan="1"><label>No emitidos</label></th>
                          <th colspan="1"><label>Total</label></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><label for=""><strong>Mujeres</strong></label></td>
                          <td><strong><label
                                 >{{resultado.emitidosMujer + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                 >{{resultado.noEmitidosMujer + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                >{{resultado.totalMujeresIncritas + ' Mujeres'}}</label></strong></td>

                      </tr>
                      <tr>
                          <td><label for=""><strong>Hombres</strong></label></td>
                          <td><strong><label
                                 >{{resultado.emitidosHombre + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                >{{resultado.noEmitidosHombre + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                  >{{resultado.totalHombreIncritos +  ' Hombres'}}</label></strong></td>

                      </tr>

                      <tr>
                          <td><label for=""><strong>Total</strong></label></td>
                          <td><strong><label
                                 >{{resultado.totalEmitidos + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                 >{{resultado.totalNoEmitidos + ' Votos'}}</label></strong></td>
                          <td><strong><label
                                  >{{resultado.totalIncritos + ' Votantes'}}</label></strong></td>

                      </tr>
                    </tbody>
                  </table>

                <br/>
                <br/>
                  <table id="porcentajepersonas"  class="table table-bordered border-primary" style="text-align: center;">
                      <thead>
                        <tr >



                          <th colspan="4"><label>Porcentaje de Participación </label></th>

                        </tr>

                      <tr>
                          <th colspan="1"><label >Genero</label></th>
                          <th colspan="1"><label >Emitidos</label></th>
                          <th colspan="1"><label >No emitidos</label></th>
                          <th colspan="1"><label >Total</label></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td><label for=""><strong>Mujeres</strong></label></td>

                          <td ><strong><label
                               >{{resultado.porcentajeEmitidosMujer+'%'}}</label></strong></td>
                          <td ><strong><label
                                  >{{resultado.porcentajenoEmitidosMujer+'%'}}</label></strong></td>
                          <td><strong><label
                                  >{{resultado.porcentajetotalMujeresIncritas+'%'}}</label></strong></td>

                      </tr>
                      <tr>
                        <td><label for=""><strong>Hombres</strong></label></td>


                          <td ><strong><label
                                >{{resultado.porcentajeEmitidosHombre+'%'}}</label></strong></td>
                          <td ><strong><label
                                   >{{resultado.porcentajenoEmitidosHombre+'%'}}</label></strong></td>
                          <td><strong><label>{{resultado.porcentajetotalHombreIncritas+'%'}}
                                 </label></strong></td>

                      </tr>

                      <tr>
                        <td><label for=""><strong>Total</strong></label></td>

                          <td ><strong><label
                                >{{resultado.porcentajeTotalEmitidos+'%'}}</label></strong></td>
                          <td ><strong><label
                                >{{resultado.porcentajeTotalNoEmitido+'%'}}</label></strong></td>
                          <td><strong><label
                                 >{{resultado.porcentajeTotal+'%'}}</label></strong></td>

                      </tr>
                    </tbody>
                  </table>
            </ion-card-content>

      </ion-card>
      <br>
  <br>


  <ion-card>
  <ion-card-header>

  </ion-card-header>
  <ion-card-content>

  <table id="preferencias"  class="table table-bordered border-primary" style="text-align: center;">
          <thead>

            <tr >



              <th colspan="5"><label>Contador de Preferencias </label></th>

            </tr>
  <tr>
  <th colspan="1"><label >Preferencias marcada normal</label></th>
  <th colspan="1"><label >Preferencias en blanco</label></th>

  <th colspan="1"><label for="">Preferencias nulas</label></th>

  <th colspan="1"><label for="">Preferencias no votantes</label></th>

  <th colspan="1"><label for="">Total preferencias posibles</label></th>
  </tr>
          </thead>
          <tbody>
  <tr>

  <td><strong><label
      >{{resultado.preferenciasNormal +' Preferencias'}}</label></strong></td>
  <td><strong><label
      >{{resultado.preferenciaBlanco +' Preferencias'}}</label></strong></td>

  <td><strong><label
      >{{resultado.preferenciaNulas +' Preferencias'}}</label></strong></td>

      <td><strong><label

      >{{resultado.preferenciasNoUtilizadaNoVotante +' Preferencias'}}</label></strong></td>
  <td><strong><label

      >{{resultado.totalPrefereciasPosibles +' Preferencias'}}</label></strong></td>


  </tr>

  </tbody>




  </table>

  <br/>

  <br/>

  <table id="porcentajePreferencias"  class="table table-bordered border-primary" style="text-align: center;">
          <thead>
            <tr >



              <th colspan="5"><label>Porcentaje de Preferencias </label></th>

            </tr>
  <tr>
  <th colspan="1"><label for="">Preferencias marcada normal</label></th>
  <th colspan="1"><label for="">Preferencias en blanco</label></th>

  <th colspan="1"><label for="">Preferencias nulas</label></th>
  <th colspan="1"><label for="">Preferencias no Votantes</label> </th>

  <th colspan="1"><label for="">Total preferencias posibles</label></th>
  </tr>
          </thead>
        <tbody>
  <tr>

  <td><strong><label
      >{{resultado.porcentajepreferenciasNormal +'%'}}</label></strong></td>

  <td><strong><label
      >{{resultado.porcentajepreferenciaBlanco +'%'}}</label></strong></td>

  <td><strong><label
      >{{resultado.porcentajepreferenciaNulas +'%'}}</label></strong></td>
  <td><strong><label
      >{{resultado.porcentajepreferenciasNoUtilizadaNoVotante +'%'}}</label></strong></td>
      <td><strong><label
      >{{resultado.porcentajetotalPrefereciasPosibles +'%'}}</label></strong></td>


  </tr>

  </tbody>
  </table>

  </ion-card-content>

  </ion-card>
