import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuracion } from 'src/app/class/configuracion';

import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.css']
})
export class CierreComponent implements OnInit {
  config : Configuracion = {
    id:0,
   estado:'',
   fechaApertura:'',
   fechaCierre:''
      };
      today = new Date();
  estado='';
  votacion : Votacion[] = [];

  user : User[] = [];

  usuMF: string='';
  passMF: string='';

  usuCE: string = '';
  passCE: string = '';

  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService) { }

  ngOnInit(): void {
    this.getVotacion();
    this.getUsers();

    this.getTraerConfiguracion();
  }
  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }

  getTraerConfiguracion(){
    this.votacionService.verConfiguracion(1).subscribe( data  => {
   this.config=data;
   const [dateValues, timeValues] = this.config.fechaApertura.split(' ');

   const [dateValues2, timeValues2] = this.config.fechaCierre.split(' ');

console.log(dateValues);
   const [year,month, day] = dateValues.split('-');

   const [year2,month2, day2] = dateValues2.split('-');

   const [hours, minutes, seconds] = timeValues.split(':');

   const [hours2, minutes2, seconds2] = timeValues2.split(':');

   const date = new Date(
     +year,
     +month - 1,
     +day,
     +hours,
     +minutes,
     +seconds,
   );

   const date2 = new Date(
    +year2,
    +month2 - 1,
    +day2,
    +hours2,
    +minutes2,
    +seconds2,
  );


   //  👇️️ Sat Sep 24 2022 07:30:14
   console.log('dd',date);
   console.log('d2',date2);
   console.log(this.today);
   if(date > this.today ){
    this.estado='no iniciada';
   }
   else{
    this.estado='en proceso';
   }

   if(this.today > date2 ){
     this.estado='proceso finalizado'
   }

  });

}
  getUsers() {
    this.votacionService.MFCE().subscribe(
      res => this.user=res,
      err=> console.log(err)
    )
  }

  cierre(){

    if(this.passCE == '' || this.passMF == '' || this.usuMF == '' || this.usuCE == ''){
      Swal.fire('Estimados/a', 'se deben ingresar todos los campos', 'info');
    }
    else{

        this.votacionService.cierreVotacion(this.usuMF,this.passMF,this.usuCE,this.passCE).subscribe(
          res  =>{

            this.router.navigate(['/login'])

          },
          err => {


           if(err.error.message === 'credenciales ministro de fe incorrectas'){
            Swal.fire('Estimados/a', ' las credenciales del ministro de fe estan incorrectas!', 'info');
           }
           if(err.error.message === 'credenciales comision electoral incorrectas'){
            Swal.fire('Estimados/a', ' las credenciales de la comision electoral estan incorrectas!', 'info');
           }

          }
        );

    }

  }

}
