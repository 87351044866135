import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit {


  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };
  votacion : Votacion[] = [];

  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,

    private router : Router,

    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {

    this.getVotacion();
    this.votacionService.user().subscribe(
      {
      next: (res: any) => {
       // this.message = `Hola ${res.first_name} ${res.last_name}`;
       console.log(res)
       this.user=res;
        VotacionService.authEmitter.emit(true);
      },
      error : err => {
        //this.message =` Tu no estas autenticado`;
        VotacionService.authEmitter.emit(false);
      }
      });
  }
  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }
  generarResultado(id :number){
    this.votacionService.generarResultado(id,null).subscribe(
      res =>
      this.router.navigate(['/verResultado/'+id],{
        skipLocationChange: true})
      ,

      err=> console.log(err)
    )
  }
}
