import { Component, OnInit } from '@angular/core';
import { userRR } from 'src/app/class/user-rr';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-claves',
  templateUrl: './claves.component.html',
  styleUrls: ['./claves.component.css']
})
export class ClavesComponent implements OnInit {

  userPDF3 : Array<userRR> = [];

  constructor(


    private votacionService: VotacionService,
  ) { }

  ngOnInit(): void {
    this.claves();
  }

claves(){
  this.votacionService.claves().subscribe(user =>
    {
      this.userPDF3 = user;
    }
    ,
    error =>  {console.log(error);
    }
  )

}

}
