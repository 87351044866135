<main class="form-signin w-100 m-auto">
  <form [formGroup]="form">

    <h1 class="h3 mb-3 fw-normal">Agregar Votación</h1>


    <div class="form-floating">
      <input type="text" formControlName="nombre"  name="nombre"  class="form-control"  placeholder="Nombre Votación" autofocus required >
      <span *ngIf="this.form.get('nombre')?.errors?.['required']">
        (El nombre de la votacion no puede quedar vacío)
      </span>
      <label for="floatingInput">Nombre Votación</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="text" formControlName="descripcion"  name="descripcion"  class="form-control"  placeholder="Nombre Sindicato" autofocus required>
      <span *ngIf="this.form.get('descripcion')?.errors?.['required']">
        (la descripcion de la votacion no puede quedar vacío)
      </span>
      <label for="floatingInput">Nombre Sindicato</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="datetime-local" [(ngModel)]='fechaApertura' formControlName="fechaApertura" id="fechaApertura"  name="fechaApertura"  class="form-control"  placeholder="Fecha Apertura" required>
      <span *ngIf="this.form.get('fechaApertura')?.errors?.['required']">
        (la fecha Apertura de la votacion no puede quedar vacío)
      </span>
      <label>Fecha Apertura"</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="datetime-local" [(ngModel)]='fechaCierre'  formControlName="fechaCierre" id="fechaCierre" name="fechaCierre"  class="form-control"  placeholder="Fecha Cierre" required>
      <span *ngIf="this.form.get('fechaCierre')?.errors?.['required']">
        (la fecha Cierre de la votacion no puede quedar vacío)
      </span>
      <label>Fecha Cierre</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="number" formControlName="maximoOpciones"  name="maximoOpciones"  class="form-control"  placeholder="maximoOpciones" autofocus required>
      <span *ngIf="this.form.get('maximoOpciones')?.errors?.['required']">
        (El maximo de opciones  de la votacion no puede quedar vacío)
      </span>
      <label for="floatingInput">Maximo Opciones</label>
    </div>
    <br>






  <br>
    <button   class="w-100 btn btn-lg btn-primary" (click)="submitVotacionForm()"  type="submit">Agregar</button>



  </form>
</main>
