import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Categoria } from 'src/app/class/categoria';
import { Opcion } from 'src/app/class/opcion';
import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { Voto } from 'src/app/class/voto';
import { VotacionService } from 'src/app/services/votacion.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-formato-voto',
  templateUrl: './formato-voto.component.html',
  styleUrls: ['./formato-voto.component.css']
})
export class FormatoVotoComponent implements OnInit {

  opcion : Opcion[] = [];
  opcion2 : Opcion[] = [];
  url:string=environment.api;
  cantidadRespuestas: any = 0;
  maximopreferencia: any = 0;
  clave: string = '';
  tipovoto : string ='';
  preguntasData: any = [];
  sele: any=[];
  votando:boolean=false;
  vatarok: any = null;
  config: any = {};

  votacion22 : Votacion[] = [];
  votacion1 : Votacion = {

    id:0,
    nombre:'',
    tipo:'',
    descripcion: '',
    imagen:'',
    fechaApertura: new Date() ,
    fechaCierre : new Date() ,
    maximoOpciones: 0,
    participacionHombre : 0,
    participacionMujer : 0 ,
    porcentajeParticipacion : 0,
    totalIncritos : 0,
    totalIncritosHombre : 0 ,
    totalIncritosMujer : 0,
    votosHombre : 0 ,
    votosMujer : 0,
    votosRecibidos : 0,
    estado : ''

  };

  user : User = {
id:0,
nombre : '',
apellido: '',
autorizado: true,
celular : '',
claveEnviada: '',
claveGenerada : '',
email : '' ,
fechaVoto : new Date()  ,
genero : '',
grupo : '',
password : '',
passwordEnviar: '',
rol : '',
rut: '',
numeroSerie : '',
secret : '',
username : '',
verificacionRc : ''
  };

  categoria : Categoria = {
    id:0,
    texto: '',
    detalle: ''
  }

  voto : Voto = {
  id : 0 ,
  fechaVoto : new Date(),
  generoVotante : '',
  hash : '',
   ip : '',
   opcionesBlancas : 0,
   opcionesNoUsadas : 0,
   opcionesNulas : 0,
   opcionesValidas : 0,
   tipoVoto : '',
   verificado : ''
  }

  constructor(

    private activatedRoute: ActivatedRoute,
    private votacionService: VotacionService,

  ) { }





  ngOnInit(): void {

    const id: string = this.activatedRoute.snapshot.params['id'];
  //  const rut: string = this.activatedRoute.snapshot.params['rut'];

   // this.usuario(rut);
    this.opciones(id);
    this.votacion(Number(id));
    this.categorias(Number(id));

  }
  usuario(rut: string) {
    this.votacionService.verUsuario(rut).subscribe( (res) =>{
      console.log(res);
      this.user=res;


    },
    err => console.log(err)
   );
  }
  votacion(id:number) {
    this.votacionService.verVotacion(id).subscribe( (res) =>{
      console.log(res);
      this.votacion1=res;


    },
    err => console.log(err)
   );
  }

  categorias(id:number) {
    this.votacionService.getCategoria(id).subscribe( (res) =>{
      console.log(res);
      this.categoria=res;


    },
    err => console.log(err)
   );
  }
  opciones(id:string) {
   this.votacionService.getOpcionesCategoria(Number(id)).subscribe( (res) =>{
    console.log(res);
    this.opcion=res;


  },
  err => console.log(err)
 );
  }









}
