
<div  style="text-transform:uppercase" class="container-fluid" align="center">

  <br>

  <mat-form-field  style="text-transform:uppercase" appearance="fill" style="width: 300px;">
    <mat-label>Selecciona la votación</mat-label>
    <mat-select  [(ngModel)]="papeletaA" (selectionChange)="getPapeleta()">

      <mat-option style="color: red" value="{{item.id}}" *ngFor="let item of data">{{item.nombre}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-tab-group align="center"  style="text-transform:uppercase;text-align: center;">
    <div class="row" align="center"  >

      <mat-tab  label="Resumen Papeleta:  {{ item.nombre }}" *ngFor="let item of [dataPapeleta]">

        <mat-card align="center">
          <mat-card-content>
        <mat-list  style="text-transform:uppercase">
          <mat-list-item>{{ item.nombre }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>{{item.descripcion}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Fecha Inicio: {{ item.fechaApertura | date:'yyyy-MM-dd HH:mm:ss' }}

          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            Fecha Termino:
            {{ item.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Nº máximo de preferencias: {{item.maximoOpciones}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Total inscritos: {{item.totalIncritos}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>  Inscritos Hombres : {{item.totalIncritosHombre==null ? '0' : item.totalIncritosHombre}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Inscritos Mujeres: {{item.totalIncritosMujer ==null ? '0' : item.totalIncritosMujer }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item  style="text-transform:uppercase">{{item.estado+ ' - ' + estado}}</mat-list-item>
          <mat-divider></mat-divider>
         </mat-list>

         <mat-tab-group  style="text-transform:uppercase">
          <mat-tab label="Votos">
            <mat-toolbar style="background-color: #87CEEB;color: white;">
              <mat-toolbar-row>
                <span *ngIf="item.votosRecibidos!=null">Votos totales: {{resultado.totalEmitidos}} votos</span>
                <span *ngIf="item.votosRecibidos==null">Votos totales: {{item.votosRecibidos}} votos</span>
              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.votosHombre!=null && item.totalIncritosHombre!=null">Votos hombres: {{resultado.emitidosHombre}} votos</span>
                <span *ngIf="item.votosHombre==null && item.totalIncritosHombre!=null">Votos hombres: 0 votos</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.votosMujer!=null && item.totalIncritosMujer!=null">Votos mujeres: {{resultado.emitidosMujer}} votos</span>
                <span *ngIf="item.votosMujer==null && item.totalIncritosMujer!=null">Votos mujeres: 0 votos</span>

              </mat-toolbar-row>
            </mat-toolbar>



          </mat-tab>
          <mat-tab label="Participación %">
            <mat-toolbar style="background-color: #8db600;color: white;">
              <mat-toolbar-row>
                <span *ngIf="item.porcentajeParticipacion!=null">Participación Global: {{round(resultado.porcentajeTotalEmitidos)}}%</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.participacionHombre!=null && item.totalIncritosHombre!=null">Participación hombres: {{round(resultado.porcentajeEmitidosHombre)}}%</span>
                <span *ngIf="item.participacionHombre==null && item.totalIncritosHombre!=null">Participación hombres: 0%</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.participacionMujer!=null && item.totalIncritosMujer!=null">Participación mujeres: {{round(resultado.porcentajeEmitidosMujer)}}%</span>
                <span *ngIf="item.participacionMujer==null && item.totalIncritosMujer!=null">Participación mujeres: 0%</span>
              </mat-toolbar-row>
            </mat-toolbar>
          </mat-tab>

        </mat-tab-group>
        </mat-card-content>
        </mat-card>









    <br>

    <br>
    <br>




    <div class="chart_box" *ngIf="item.votosRecibidos > 0 ">
    <google-chart #chart
    [title]="title"
    [type]="type"
    [data]="[['Votos emitidos',resultado.totalEmitidos],['Votos no emitidos',item.totalIncritos - resultado.totalEmitidos]]"
    [columns]="columnNames"
    [options]="options"
    >
  </google-chart>
</div>


<div class="chart_box" *ngIf="item.totalIncritosHombre!=null && item.totalIncritosHombre > 0 " >
  <google-chart #chart
  [title]="title3"
  [type]="type"
  [data]="[['Votos emitidos',  resultado.emitidosHombre  ],['Votos no emitidos', item.totalIncritosHombre- resultado.emitidosHombre]]"
  [columns]="columnNames"
  [options]="options"
  >
</google-chart>

</div>

<div class="chart_box" *ngIf="item.totalIncritosMujer!=null && item.totalIncritosMujer > 0 " >
  <google-chart #chart
  [title]="title4"
  [type]="type"
  [data]="[['Votos emitidos',  resultado.emitidosMujer  ],['Votos no emitidos', item.totalIncritosMujer - resultado.emitidosMujer]]"
  [columns]="columnNames"
  [options]="options"
  >
</google-chart>

</div>




      </mat-tab>

    </div>
  </mat-tab-group>






</div>

<div class="mat-elevation-z8" *ngIf="votosHora.length > 0 " >
  <br/>
  <br/>
  <h3 style="text-align: center;"><strong>Votos por hora</strong></h3>
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Horario  </th>
      <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Cantidad de votos</th>
      <td mat-cell *matCellDef="let element"> {{element.total}} </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
