<main class="form-signin w-100 m-auto">
  <form [formGroup]="form">

    <h1 class="h3 mb-3 fw-normal">Agregar Configuracion</h1>


    <div class="form-floating">
      <input type="text" formControlName="estado"  name="estado"  class="form-control"  placeholder="Estado Votación" autofocus required >
      <span *ngIf="this.form.get('estado')?.errors?.['required']">
        (El estado de la votacion no puede quedar vacío)
      </span>
      <label for="floatingInput">Estado Votación</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="datetime-local" [(ngModel)]='fechaApertura' formControlName="fechaApertura" id="fechaApertura"  name="fechaApertura"  class="form-control"  placeholder="Fecha Apertura" required>
      <span *ngIf="this.form.get('fechaApertura')?.errors?.['required']">
        (la fecha Apertura de la votacion no puede quedar vacío)
      </span>
      <label>Fecha Apertura"</label>
    </div>
    <br>

    <div class="form-floating">
      <input type="datetime-local" [(ngModel)]='fechaCierre'  formControlName="fechaCierre" id="fechaCierre" name="fechaCierre"  class="form-control"  placeholder="Fecha Cierre" required>
      <span *ngIf="this.form.get('fechaCierre')?.errors?.['required']">
        (la fecha Cierre de la votacion no puede quedar vacío)
      </span>
      <label>Fecha Cierre</label>
    </div>
    <br>


    <div class="form-floating">

      <select formControlName="tripleFactor" name="tripleFactor" id="tripleFactor">
        <option value="si">Si</option>
        <option value="no">No</option>

      </select>
      <span *ngIf="this.form.get('tripleFactor')?.errors?.['required']">
        (El triple factor no puede quedar vacion )
      </span>
      <label for="cars">Triple Factor:</label>
    </div>
    <br>





  <br>
    <button   class="w-100 btn btn-lg btn-primary" (click)="crearConfig()"  type="submit">Agregar</button>



  </form>
</main>
