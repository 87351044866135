<h1>Claves ministro de fe y Comision electoral</h1>
<br>
<br>
<table   class="table">



  <thead>


  <tr>


    <th><label>Usuario</label></th>
    <th><label>Password</label></th>
    <th><label>Rol</label></th>



  </tr>

  </thead>

  <tr *ngFor="let ex of userPDF3">


    <td><label>{{ex.username}}</label></td>
    <td><label>{{ex.password}}</label></td>
    <td><label>{{ex.rol}}</label></td>



  </tr>


  </table>
