<main class="form-signin w-100 m-auto">
    <form [formGroup]="form" (submit)="submit()" >
      
      <h1 class="h3 mb-3 fw-normal">Por Favor Ingrese sus codigo</h1>
  
      
      <div class="form-floating">
        <input formControlName="code"  class="form-control"  placeholder="6 digitos">
        <label for="floatingInput">Codigo</label>
      </div>
     
      
     
      <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Ingresar</button>
    
    </form>

    <img style="width: 300px;" [src]="loginData.img" *ngIf="loginData.img" />
  </main>