<div style="text-align:center ;">
  <form [formGroup]="form">
      <br>
      <h3>Crear Usuario MF O Dirigente</h3>
      <br>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Nombre</label>
        <input type="text" formControlName="nombre"  name="nombre" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

      </div>
      <div class="mb-3">
        <label for="exampleInputPassword1" class="form-label">Apellido</label>
        <input type="text" formControlName="apellido"  name="apellido" class="form-control" id="exampleInputPassword1">
      </div>
      <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Correo</label>
          <input type="email" formControlName="email"  name="email" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Celular</label>
          <input type="text" formControlName="celular"  name="celular" class="form-control" id="exampleInputPassword1">
        </div>

        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Rol</label>
          <select class="form-select" formControlName="rol"  name="rol" aria-label="Default select example">
              <option selected>Seleccione</option>
              <option value="ministro">ministro</option>
              <option value="dirigente">dirigente</option>
              <option value="admin">admin</option>
            </select>
        </div>

      <button type="submit" (click)="submitUsuarioForm()" class="btn btn-primary">Guardar</button>
    </form>

  </div>
