import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Regiones } from 'src/app/class/regiones';

import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { ExporterService } from 'src/app/services/exporter.service';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  regiones: Regiones [] = [];
  myDataArray : User[]=[]
  myDataArray2 : User[]=[]
  myDataArray3 : User[]=[]
  votacion : Votacion[] = [];

  constructor( private formBuilder : FormBuilder,
    private http: HttpClient,


    private votacionService: VotacionService,
    private exporterService: ExporterService) { }

  ngOnInit(): void {

    this.getRegiones(1);
    this.getVotacion();


  }

  getRegiones(id:number){
    this.votacionService.Regiones(id).subscribe(
      res => this.regiones=res,
      err=> console.log(err)
    )
}

  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }
  votantes(id:number,nombre:string){
    this.votacionService.votantes(id).subscribe(
      res => {this.myDataArray2=res
        this.exporterService.exportToExcel(this.myDataArray2,'votantes_'+nombre)
        this.myDataArray2.length=0;
      },
      err=> console.log(err)
    )
  }

  incritos(id:number,nombre:string){
    this.votacionService.incritos(id).subscribe(
      res => {this.myDataArray3=res
        this.exporterService.exportToExcel(this.myDataArray3,'incritos_'+nombre)
        this.myDataArray3.length=0;
      },
      err=> console.log(err)
    )
  }
  novotante(id:number,nombre:string){

    this.votacionService.novotantes(id).subscribe(
      res => {this.myDataArray=res
        this.exporterService.exportToExcel(this.myDataArray,'no_votantes_'+nombre)
        this.myDataArray.length=0;
      },
      err=> console.log(err)
    )




  }

  novotanteRegion(id:number,nombre:string){
    var DropdownList = (document.getElementById("regiones")) as HTMLSelectElement;

    this.votacionService.novotantesRegion(id,DropdownList.value).subscribe(
      res => {this.myDataArray=res
        this.exporterService.exportToExcel(this.myDataArray,'no_votantes_'+nombre+'_'+JSON.stringify(DropdownList.value))
        this.myDataArray.length=0;
      },
      err=> console.log(err)
    )




  }

}
