import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { VotacionService } from 'src/app/services/votacion.service';


@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.css']
})
export class AuthenticatorComponent implements OnInit {

@Input('loginData') loginData = {
  id:0,
  img: ''
};
  form!: FormGroup;

  constructor(
    private formBuilder : FormBuilder,

    private router : Router,
    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      code : '' ,
     
    });
  }

  submit(){
    const formData = this.form.getRawValue();
    const data = this.loginData;
    
   this.votacionService.authenticatorLogin({
    ...data,
    ...formData
   }).subscribe(
    (res: any) => {
      this.votacionService.accessToken = res.token;
      VotacionService.authEmitter.emit(true);
      this.router.navigate(['/']);
    }
   );
  }


}
