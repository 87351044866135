import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { Configuracion } from 'src/app/class/configuracion';
import { Regiones } from 'src/app/class/regiones';
import { Resultado } from 'src/app/class/resultado';
import { Votacion } from 'src/app/class/votacion';
import { VotoHora } from 'src/app/class/votoHora';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-estadisticas-grupo',
  templateUrl: './estadisticas-grupo.component.html',
  styleUrls: ['./estadisticas-grupo.component.css']
})
export class EstadisticasGrupoComponent implements OnInit {
  regiones: Regiones [] = [];
  displayedColumns: string[] = ['position', 'name', ];
  dataSource! : MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator ;

  nombre!: string;
  votacion2 : Votacion = {

    id:0,
    nombre:'',
    tipo:'',
    descripcion: '',
    imagen:'',
    fechaApertura: new Date() ,
    fechaCierre : new Date() ,
    maximoOpciones: 0,
    participacionHombre : 0,
    participacionMujer : 0 ,
    porcentajeParticipacion : 0,
    totalIncritos : 0,
    totalIncritosHombre : 0 ,
    totalIncritosMujer : 0,
    votosHombre : 0 ,
    votosMujer : 0,
    votosRecibidos : 0,
    estado: ''

  };
  resultado : Resultado = {
    id: 0,
    emitidosHombre: 0,
    emitidosMujer: 0,
    noEmitidosHombre: 0,
    noEmitidosMujer : 0,
    porcentajeEmitidosHombre: 0,
    porcentajeEmitidosMujer: 0,
    porcentajenoEmitidosHombre: 0,
    porcentajenoEmitidosMujer: 0,
    porcentajepreferenciaBlanco: 0,
    porcentajepreferenciaNulas: 0,
    porcentajepreferenciasNormal: 0,
    porcentajepreferenciasNoUtilizadaNoVotante: 0,
    porcentajeTotal:0,
    porcentajeTotalEmitidos: 0,
    porcentajetotalHombreIncritas:0,
    porcentajetotalMujeresIncritas: 0,
    porcentajeTotalNoEmitido: 0,
    porcentajetotalPrefereciasPosibles: 0,
    preferenciaBlanco: 0,
    preferenciaNulas: 0,
    preferenciasNormal: 0,
    preferenciasNoUtilizadaNoVotante: 0,
    totalEmitidos:0 ,
    totalHombreIncritos: 0,
    totalIncritos: 0,
    totalMujeresIncritas: 0,
    totalNoEmitidos: 0,
    totalPrefereciasPosibles:0
  }
  config : Configuracion = {
    id:0,
   estado:'',
   fechaApertura:'',
   fechaCierre:''
      };
  private datos : any =[];
   hola =0;
   polo=0;

  votacion : Votacion[] = [];
  votosHora : Array<VotoHora> = [];
  pipe = new DatePipe('en-US');


  today = new Date();
estado='';


  fechaActual= this.pipe.transform(this.today,'yyyy-MM-dd HH:mm:ss')
	/**
	* Constructor
	*/

  data :any=[];
  votos:any=[];
  data2: any=[];
  papeletaA:any = '';
  dataPapeleta:any = {};
  p1:any=[];
  p2:any=[];
  title = 'Votos emitidos vs Votos no emitidos';
  title2 = 'Votos no emitidos hombres vs Votos no emitidos mujeres';

  title3 = 'Votos emitidos hombres vs Votos no emitidos hombres';
  title4 = 'Votos emitidos mujeres vs Votos no emitidos mujeres';
  type = ChartType.PieChart;
  type2 = ChartType.Bar;
  chartData = [];
  columnNames = ['Votos', 'Porcentaje'];

  columnNames2 = ['1'];
  options = {
    'width':'100%',
                       'height':500,
                       'curveType': "function",

                       'chartArea': {'width': '98%'},
                       'axisTitlesPosition': 'in',
                       'vAxis': {'textPosition': 'in'},


  };
  width = 800;
  height = 400;
  constructor(





    private votacionService: VotacionService
  ) { }
  ngOnInit(): void {
    this.getRegiones(1);
    setTimeout(() => {
      this.getVotacion();
    },0);



  }

  getVotosHora(id:number,grupo:string):void{

    this.votacionService.votosPorHoraRegion(id,grupo).subscribe( votacion => {
      this.votosHora=votacion;
      this.dataSource=new MatTableDataSource(this.votosHora);
      this.dataSource.paginator = this.paginator;

    }
    );
  }

  getPapeleta(){
    for (let index = 0; index <  this.data.length; index++) {
      if (this.papeletaA==this.data[index].id){
        this.dataPapeleta = this.data[index];
       // this.getVotosHora(this.data[index].id);
        this.data2.length=0;

      }

    }

  }






round(valor:number){
  return Math.round(valor);
}


validar(){

  var votacion = (document.getElementById("vota")) as HTMLSelectElement;
  var grupo = (document.getElementById("grupo")) as HTMLSelectElement;
  this.votacionService.verVotacion(1).subscribe(
    res => { this.votacion2=res;
     // console.log(res);
    /* this.votacionService.traerFtoo(this.votacion.imagen).subscribe(
      cc => {
        this.url=environment.api+'image/'+this.votacion.imagen


      }
     )
     */

    },
  //  err => console.log(err)
  )
  this.votacionService.generarResultadoRegion(parseInt(votacion.value),grupo.value,null).subscribe(
    res => {
      this.votacionService.getResultadoRegion(parseInt(votacion.value),grupo.value).subscribe(
        res =>{ this.resultado=res;
  console.log(res);
  this.getVotosHora(parseInt(votacion.value),grupo.value);
  $('#vista').css('display','block');
        }
        ,
        err=> console.log(err)
      )
    },

    err=> console.log(err)
  )

}

  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
       (data:any) => {

      //  this.chartData = [];

       this.data=[];
        for(let index = 0; index < data.length; index++){

          if (index==0){
            this.papeletaA = data[index].id;
            this.dataPapeleta =  data[index];

          }
          this.data.push(data[index])




        }



      });



  }






  getRegiones(id:number){
    this.votacionService.Regiones(id).subscribe(
      res => this.regiones=res,
      err=> console.log(err)
    )
}
}
