

<br>


<table id="votaciontodo"  >



    <thead>
        <tr>

            <th><label>Datos</label></th>
            <th><label>Resultado</label></th>

        </tr>

        </thead>
    <tr>
        <td>Votacion</td>
        <td><strong><label>{{votacion.nombre}}</label></strong></td>
    </tr>
    <tr>
        <td>Sindicato</td>
        <td><strong><label
                >{{votacion.descripcion}}</label></strong></td>
    </tr>
    <tr>
        <td>Inicio de la Votación</td>
        <td><strong><label
                >{{votacion.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</label></strong></td>
    </tr>
    <tr>
        <td>Término de la Votación</td>
        <td><strong><label
                >{{votacion.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</label></strong></td>
    </tr>

    <tr>
        <td>Maximo de opciones a marcar en voto</td>
        <td><strong><label
                >{{votacion.maximoOpciones}}</label></strong></td>
    </tr>

  <tr >
        <td >Ministro de Fe</td>
        <td><strong><label>{{user.nombre +' ' + user.apellido}}</label></strong></td>
    </tr>

    <tr >
        <td >Consulta</td>
        <td><strong><label>{{categoria1.texto}}</label></strong></td>
    </tr>



</table>







<div style="text-align: center;" class="btn-group flex-wrap" role="group">
<button (click)="incritos(votacion.id)" mat-raised-button color="accent"  style="margin: 10px;"
><span
class="glyphicon glyphicon-list-alt"></span> Reporte socios
inscritos XLS</button>



<button (click)="votantes(votacion.id)" mat-raised-button color="accent" style="margin: 10px;"
      ><span
class="glyphicon glyphicon-list-alt"></span> Reporte socios
inscritos votantes XLS </button> <button mat-raised-button color="accent"  style="margin: 10px;"  (click)="novotante(votacion.id)"
         ><span
class="glyphicon glyphicon-list-alt"></span> Reporte socios
inscritos no votantes XLS</button>


<button mat-raised-button color="accent" style="margin: 10px;" (click)="generarPDF()"><span
class="glyphicon glyphicon-list-alt"></span>Imprimir reporte escrutinio</button>
</div>

<div style="text-align: center;" class="btn-group flex-wrap" role="group">
  <button (click)="ImprimirincritosPDF(votacion.id)" mat-raised-button color="accent"  style="margin: 10px;"
     ><span
      class="glyphicon glyphicon-list-alt"></span> Reporte socios
    inscritos PDF</button>



     <button (click)="ImprimirVotantesPDF(votacion.id)" mat-raised-button color="accent" style="margin: 10px;"
            ><span
    class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos votantes PDF </button> <button mat-raised-button color="accent"  style="margin: 10px;"  (click)="ImprimirNoVotantesPDF(votacion.id)"
               ><span
    class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos no votantes PDF</button>


</div>

<br>
<br>

<table id="incritospdf" style="display: none;" >



<thead>


<tr>


<th><label>Nombre</label></th>
<th><label>Apellido</label></th>
<th><label>Rut</label></th>
<th><label>Genero</label></th>


</tr>

</thead>

<tr *ngFor="let ex of userPDF">


<td><label>{{ex.nombre}}</label></td>
<td><label>{{ex.apellido}}</label></td>
<td><label>{{ex.rut}}</label></td>
<td><label>{{ex.genero}}</label></td>



</tr>


</table>

<table id="votantespdf" style="display: none;" >



<thead>


<tr>


<th><label>Nombre</label></th>
<th><label>Apellido</label></th>
<th><label>Rut</label></th>
<th><label>Genero</label></th>
<th><label>Fecha Voto</label></th>

</tr>

</thead>

<tr *ngFor="let ex of userPDF2">


<td><label>{{ex.nombre}}</label></td>
<td><label>{{ex.apellido}}</label></td>
<td><label>{{ex.rut}}</label></td>
<td><label>{{ex.genero}}</label></td>
<td><label>{{ex.fechaVoto}}</label></td>


</tr>


</table>

<table id="novotantespdf"  style="display: none;">



<thead>


<tr>


<th><label>Nombre</label></th>
<th><label>Apellido</label></th>
<th><label>Rut</label></th>
<th><label>Genero</label></th>


</tr>

</thead>

<tr *ngFor="let ex of userPDF3">


<td><label>{{ex.nombre}}</label></td>
<td><label>{{ex.apellido}}</label></td>
<td><label>{{ex.rut}}</label></td>
<td><label>{{ex.genero}}</label></td>



</tr>


</table>

    <br>
    <br>
    <ion-card>

                 <table id="tableopciones"
                            class="table table-condensed table-grid table-responsive table-bordered">



                        <thead>


                        <tr>


                            <th><label>Opción</label></th>
                            <th><label>Votos</label></th>
                            <th><label>% Votos Recibidos</label></th>


                        </tr>

                        </thead>

                        <tr *ngFor="let ex of opciones2">


                            <td><label>{{ex.nombreOpcion}}</label></td>

                            <td><h5 >{{ex.cantidadOpcion + ' Votos'}}</h5></td>
                            <td><h5 >{{round((ex.cantidadOpcion / resultado.preferenciasNormal)*100) + '%' }}</h5></td>

                        </tr>
                        <tr>
                            <td><h3>Total Opciones Validas: </h3> </td>
                            <td> <h3 >{{resultado.preferenciasNormal + ' Preferencias'}}</h3> </td>
                            <td> <h3 >100%</h3> </td>

                        </tr>

                    </table>


    </ion-card>

    <br>
    <br>

    <ion-card>
        <ion-card-header>
            <ion-card-title>Sobre la participación</ion-card-title>
            <ion-card-subtitle>Contadores de Votos</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
                <table id="contadorpersonas"  class="table table-condensed table-striped table-bordered">
                    <thead>
                        <tr >



                            <th colspan="4"><label>Contadores de Votos </label></th>

                          </tr>

                    <tr>
                        <th><label>Genero</label></th>
                        <th><label> Emitidos</label></th>
                        <th><label>No emitidos</label></th>
                        <th><label>Total</label></th>
                    </tr>
                </thead>
                    <tr>
                        <td>Mujeres</td>
                        <td><strong><label
                               >{{resultado.emitidosMujer + ' Votos'}}</label></strong></td>
                        <td><strong><label
                               >{{resultado.noEmitidosMujer + ' Votos'}}</label></strong></td>
                        <td><strong><label
                              >{{resultado.totalMujeresIncritas + ' Mujeres'}}</label></strong></td>

                    </tr>
                    <tr>
                        <td>Hombres</td>
                        <td><strong><label
                               >{{resultado.emitidosHombre + ' Votos'}}</label></strong></td>
                        <td><strong><label
                              >{{resultado.noEmitidosHombre + ' Votos'}}</label></strong></td>
                        <td><strong><label
                                >{{resultado.totalHombreIncritos +  ' Hombres'}}</label></strong></td>

                    </tr>

                    <tr>
                        <td>Total</td>
                        <td><strong><label
                               >{{resultado.totalEmitidos + ' Votos'}}</label></strong></td>
                        <td><strong><label
                               >{{(resultado.totalHombreIncritos+ resultado.totalMujeresIncritas) - resultado.totalEmitidos + ' Votos'}}</label></strong></td>
                        <td><strong><label
                                >{{(resultado.totalHombreIncritos+ resultado.totalMujeresIncritas) + ' Votantes'}}</label></strong></td>

                    </tr>

                </table>

                <h3>Porcentajes</h3>
                <table id="porcentajepersonas" class="table table-condensed table-striped table-bordered">
                    <thead>
                    <tr>
                        <td></td>
                        <td>Emitidos</td>
                        <td>No emitidos</td>
                        <td>Total</td>
                    </tr>
                    </thead>
                    <tr>
                        <td>Mujeres</td>

                        <td ><strong><label
                             >{{round2((resultado.emitidosMujer*100)/ resultado.totalMujeresIncritas)+'%'}}</label></strong></td>
                        <td ><strong><label
                                >{{round2(((resultado.totalMujeresIncritas - resultado.emitidosMujer)*100) / resultado.totalMujeresIncritas)+'%'}}</label></strong></td>
                        <td><strong><label
                                >{{round2((resultado.totalMujeresIncritas*100)/ resultado.totalMujeresIncritas)+'%'}}</label></strong></td>

                    </tr>
                    <tr>
                        <td>Hombres</td>

                        <td ><strong><label
                              >{{round2((resultado.emitidosHombre*100)/ resultado.totalHombreIncritos)+'%'}}</label></strong></td>
                        <td ><strong><label
                                 >{{round2(((resultado.totalHombreIncritos - resultado.emitidosHombre)*100) / resultado.totalHombreIncritos)+'%'}}</label></strong></td>
                        <td><strong><label>{{round2((resultado.totalHombreIncritos*100)/ resultado.totalHombreIncritos)+'%'}}
                               </label></strong></td>

                    </tr>

                    <tr>
                        <td>Total</td>

                        <td ><strong><label
                              >{{round2(((resultado.emitidosHombre + resultado.emitidosMujer) * 100) /(resultado.totalHombreIncritos + resultado.totalMujeresIncritas))+'%'}}</label></strong></td>
                        <td ><strong><label
                              >{{round2((((resultado.totalHombreIncritos +resultado.totalMujeresIncritas) - (resultado.emitidosHombre + resultado.emitidosMujer)) * 100) / (resultado.totalHombreIncritos + resultado.totalMujeresIncritas))+'%'}}</label></strong></td>
                        <td><strong><label
                               >{{round2(((resultado.totalHombreIncritos +resultado.totalMujeresIncritas) * 100) /(resultado.totalHombreIncritos +resultado.totalMujeresIncritas))+'%'}}</label></strong></td>

                    </tr>

                </table>
          </ion-card-content>

    </ion-card>
    <br>
<br>


<ion-card>
<ion-card-header>
<ion-card-title>Sobre las preferencias</ion-card-title>
<ion-card-subtitle>Contadores</ion-card-subtitle>
</ion-card-header>
<ion-card-content>

<table id="preferencias" class="table table-condensed table-striped table-bordered">
        <thead>


<tr>
<td>Preferencias marcada normal</td>
<td>Preferencias en blanco</td>

<td>Preferencias nulas</td>

<td>Preferencias no votantes</td>

<td>Total preferencias posibles</td>
</tr>
        </thead>
<tr>

<td><strong><label
    >{{resultado.preferenciasNormal +' Preferencias'}}</label></strong></td>
<td><strong><label
    >{{resultado.preferenciaBlanco +' Preferencias'}}</label></strong></td>

<td><strong><label
    >{{resultado.preferenciaNulas +' Preferencias'}}</label></strong></td>

    <td><strong><label

    >{{resultado.preferenciasNoUtilizadaNoVotante +' Preferencias'}}</label></strong></td>
<td><strong><label

    >{{(resultado.totalHombreIncritos+ resultado.totalMujeresIncritas) * votacion.maximoOpciones +' Preferencias'}}</label></strong></td>


</tr>






</table>

<h3>Porcentajes</h3>
<table id="porcentajePreferencias" class="table table-condensed table-striped table-bordered">
        <thead>
<tr>
<td>Preferencias marcada normal</td>
<td>Preferencias en blanco</td>

<td>Preferencias nulas</td>
<td>Preferencias no Votantes</td>

<td>Total preferencias posibles</td>
</tr>
        </thead>
<tr>

<td><strong><label
    >{{((resultado.preferenciasNormal * 100) / ((resultado.totalHombreIncritos+ resultado.totalMujeresIncritas)* votacion.maximoOpciones)).toFixed(3) +'%'}}</label></strong></td>

<td><strong><label
    >{{(resultado.porcentajepreferenciaBlanco / ((resultado.totalHombreIncritos+ resultado.totalMujeresIncritas)* votacion.maximoOpciones) ).toFixed(3) +'%'}}</label></strong></td>

<td><strong><label
    >{{(resultado.porcentajepreferenciaNulas / ((resultado.totalHombreIncritos+ resultado.totalMujeresIncritas)* votacion.maximoOpciones) ).toFixed(3) +'%'}}</label></strong></td>
<td><strong><label
    >{{(resultado.porcentajepreferenciasNoUtilizadaNoVotante / ((resultado.totalHombreIncritos+ resultado.totalMujeresIncritas)* votacion.maximoOpciones) ).toFixed(3) +'%'}}</label></strong></td>
    <td><strong><label
    >{{resultado.porcentajetotalPrefereciasPosibles+'%'}}</label></strong></td>


</tr>


</table>

</ion-card-content>

</ion-card>
