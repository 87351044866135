import { NgModule, OnInit } from '@angular/core';

import { AgregarVotacionComponent } from './pages/agregar-votacion/agregar-votacion.component';
import { AperturaComponent } from './pages/apertura/apertura.component';

import { CategoriaComponent } from './pages/categoria/categoria.component';
import { CierreComponent } from './pages/cierre/cierre.component';
import { CrearUsuarioComponent } from './pages/crear-usuario/crear-usuario.component';
import { EstadisticasComponent } from './pages/estadisticas/estadisticas.component';
import { FormCategoriaComponent } from './pages/form-categoria/form-categoria.component';
import { FormOpcionComponent } from './pages/form-opcion/form-opcion.component';
import { FormUsuarioComponent } from './pages/form-usuario/form-usuario.component';
import { FormatoVotoComponent } from './pages/formato-voto/formato-voto.component';

import { LoginComponent } from './pages/login/login.component';
import { NoVotantesComponent } from './pages/no-votantes/no-votantes.component';
import { OpcionesComponent } from './pages/opciones/opciones.component';
import { PadronComponent } from './pages/padron/padron.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ResultadoComponent } from './pages/resultado/resultado.component';

import { VerResultadoComponent } from './pages/ver-resultado/ver-resultado.component';
import { VerUsuariosComponent } from './pages/ver-usuarios/ver-usuarios.component';
import { VerVotacionComponent } from './pages/ver-votacion/ver-votacion.component';

import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';
import { AgregarConfiguracionComponent } from './pages/agregar-configuracion/agregar-configuracion.component';
import { VerPapeletaVotacionComponent } from './pages/ver-papeleta-votacion/ver-papeleta-votacion.component';
import { ResultadoRegionComponent } from './pages/resultado-region/resultado-region.component';
import { VerResultadoRegionComponent } from './pages/ver-resultado-region/ver-resultado-region.component';
import { EstadisticasGrupoComponent } from './pages/estadisticas-grupo/estadisticas-grupo.component';
import { ClavesComponent } from './pages/claves/claves.component';
import { InicioComponent } from './pages/inicio/inicio.component';



const routes: Routes = [

  {path: 'agregarVotacion', component: AgregarVotacionComponent},
  {path: 'agregarConfiguracion', component: AgregarConfiguracionComponent},
  {path: 'verVotacion', component: VerVotacionComponent},
  {path: 'inicio', component: InicioComponent},

  {path: 'resultado', component: ResultadoComponent},
  {path: 'resultadoRegion', component: ResultadoRegionComponent},
  {path: 'clavesrr', component: ClavesComponent},
  {path: 'verResultado/:id', component: VerResultadoComponent},
  {path: 'verResultadoRegion/:id/:grupo', component: VerResultadoRegionComponent},
  {path: 'reportes', component: ReportesComponent,  pathMatch: 'full'},
  {path: 'noVotantes/:rut', component: NoVotantesComponent},
  {path: 'estadisticas', component: EstadisticasComponent   },
  {path: 'estadisticas-grupo', component: EstadisticasGrupoComponent,pathMatch: 'full'   },
  {path: 'crearUsuario', component: CrearUsuarioComponent},
  {path: 'apertura/:hash', component: AperturaComponent},
  {path: 'cierre/:hash', component: CierreComponent},
  {path: '', component: LoginComponent},
  {path: 'categoria/:id', component: CategoriaComponent},
  {path: 'verOpciones/:id', component: OpcionesComponent},
  {path: 'papeletaVotacion', component: VerPapeletaVotacionComponent},
  {path: 'padron', component: PadronComponent},
  {path: 'crearCategoria/:id', component: FormCategoriaComponent},
  {path: 'crearOpcion/:id', component: FormOpcionComponent},
  {path: 'formatovoto/:id', component: FormatoVotoComponent},
  {path: 'crearVotante', component: FormUsuarioComponent},
  {path: 'verUsuarios', component: VerUsuariosComponent},

  { path: '**', redirectTo: '', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{onSameUrlNavigation: 'reload'})],
exports: [RouterModule]
})
export class AppRoutingModule  {

}
