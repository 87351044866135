import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExporterService } from 'src/app/services/exporter.service';
import { VotacionService } from 'src/app/services/votacion.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-form-usuario',
  templateUrl: './form-usuario.component.html',
  styleUrls: ['./form-usuario.component.css']
})
export class FormUsuarioComponent implements OnInit {

  form!: FormGroup;
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService,
    private exporterService: ExporterService) { }

  ngOnInit(): void {
    this.rescataUsuarioForm();
  }
  rescataUsuarioForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      apellido : ['',[Validators.required]],
      rut: ['',[Validators.required]],
      email: ['', [Validators.required]],
      genero: ['', [Validators.required]],
      grupo: ['', [Validators.required]],




    });
  }

  submitUsuarioForm(){
    this.votacionService.crearVotante(this.form.value).subscribe(
      res =>{
        console.log(res);
        this.router.navigate(['/'])

      },
      err => console.log(err)
    );
  }


}
