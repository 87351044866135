<form [formGroup]="form">
  <table class="table">
      <thead>
        <tr>
          <th scope="col">Pregunta</th>
          <th scope="col">Descripcion</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr >
         <td>
          <input type="text" formControlName="texto"  name="texto" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
        <span>  {{categoria.texto}}</span>

        </td>
         <td>
          <input type="text" formControlName="detalle"  name="detalle" class="form-control" id="exampleInputPassword1">
        <span>{{categoria.detalle}}</span>

        </td>
         <td><a   class="w-100 btn btn-lg btn-primary" routerLink="/crearOpcion/{{categoria.id}}"  type="submit">Crear Opciones</a>
          <br>
          <br>
          <a   class="w-100 btn btn-lg btn-primary" routerLink="/verOpciones/{{categoria.id}}"  type="submit">Ver Opciones</a>
          <br>
          <br>
          <button   class="w-100 btn btn-lg btn-primary" (click)="editar(categoria.id)" type="submit">Editar Papeleta</button>
          <br>
          <br>

        </tr>


      </tbody>
    </table>

  </form>
