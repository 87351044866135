import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Opcion } from 'src/app/class/opcion';
import { VotacionService } from 'src/app/services/votacion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-opciones',
  templateUrl: './opciones.component.html',
  styleUrls: ['./opciones.component.css']
})
export class OpcionesComponent implements OnInit {
  form!: FormGroup;
  opcion : Opcion[] = [];
  url:string=environment.api;
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService,
    ) { }

  ngOnInit(): void {
    const id: string = this.activatedRoute.snapshot.params['id'];

    setTimeout(() => {   this.getOpciones(Number(id))}, 100 )
    this.getOpciones(Number(id));
    this.rescatarCategoriaForm();

  }
  rescatarCategoriaForm() {
    this.form = this.formBuilder.group({
      texto : ['',[Validators.required]],
      descripcion : ['',[Validators.required]],



    });
  }
  getOpciones(id: number) {
   this.votacionService.getOpcionesCategoria(id).subscribe( res =>
    this.opcion=res
    ,
    err =>
    console.log(err)
    );

  }

  uploadLogo(id:number,event : any){

    const id2: string = this.activatedRoute.snapshot.params['id'];

    const image = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', image);
    this.votacionService.uploadFotoOpcion(id,formdata).subscribe(
      (res) =>{
        console.log(res);
        this.router.navigate(['/verOpciones/'+id2])

      },
      err => console.log(err)
     );
  }

  eliminar(id:number){
    const id2: string = this.activatedRoute.snapshot.params['id'];
    this.votacionService.eliminarOpcion(id).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/verVotacion'])
    })
  }


  editar(id:number){

    this.votacionService.editarOpcion(id,this.form.value).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/verVotacion'])
    })
  }

}
