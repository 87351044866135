import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-papeleta-votacion',
  templateUrl: './ver-papeleta-votacion.component.html',
  styleUrls: ['./ver-papeleta-votacion.component.css']
})
export class VerPapeletaVotacionComponent implements OnInit {


  rut:string=''

  votaciones : Votacion[] = [];

  constructor( private http: HttpClient,


    private votacionService: VotacionService) { }

  ngOnInit(): void {
  }

  validar(){
    if(this.rut===''){
      Swal.fire('Estimado/a', 'el rut no puede ir vacio!', 'info');
    }
    else{
      this.votacionService.VotacionesUsuDELO(this.rut).subscribe( res => {
        this.votaciones=res

      },
      err => {
        if(err.error.message == 'rut invalido'){
          Swal.fire('Estimado/a', 'el rut ingresado no es valido!', 'info');
         }
        if(err.error.message == 'votante no encontrado'){
          Swal.fire('Estimado/a', 'Su rut no se encuentra en nuestra base de datos comuniquese con su directiva!', 'info');
         }

      })
    }

  }

}

