import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError,switchMap } from 'rxjs/operators';
import { VotacionService } from '../services/votacion.service';



@Injectable()
export class CrmInterceptor implements HttpInterceptor {
  refresh = false;

BYPASS_JW_TOKEN = new HttpContextToken(() => false);
  constructor( private votacionService: VotacionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const req = request.clone({
      setHeaders : {
        Authorization: `Bearer ${this.votacionService.accessToken}`
      }
    });

    if (req.headers.get("skip")){
    return next.handle(req);
    }
    else{

    return next.handle(req).pipe(catchError( (err: HttpErrorResponse) => {
      
      if(err.status === 401 && !this.refresh){
        this.refresh = true;
        return this.votacionService.refresh().pipe(
          switchMap((res : any) => {
            this.votacionService.accessToken = res.token;
            return next.handle(request.clone({
              setHeaders : {
                Authorization : `Bearer ${this.votacionService.accessToken}`
              }
            }));
          })
        );
      }
      this.refresh = false;
      return throwError( () => err);
      
    }));

  }
  }
}
