
<div  style="text-transform:uppercase" class="container-fluid" align="center">

  <br>
<label for="">Seleccione la votación</label>
  <div class="caja" >
    <select  name="vota" id="vota"  >
      <option *ngFor="let rr of data" [value]="rr.id">
        {{rr.nombre}}
      </option>
    </select>

  </div>




  <label for="">Seleccione el grupo</label>
  <div class="caja" >

    <select  name="grupo" id="grupo" >
      <option *ngFor="let rr of regiones" [value]="rr.regiones">
        {{rr.regiones}}
      </option>
    </select>
  </div>
  <br>
  <button type="submit" mat-raised-button style="background-color: #87CEEB;" (click)="validar()"><img src="/assets/images/consulta.png" alt="image">&nbsp;&nbsp;CONSULTAR&nbsp;&nbsp;</button>

<div id="vista" name="vista" style="display:none;">
  <mat-tab-group align="center"   style="text-transform:uppercase;text-align: center;">
    <div class="row" align="center"  >

      <mat-tab label="Resumen Papeleta:  {{ votacion2.nombre }}"  *ngFor="let item of [resultado]">

        <mat-card align="center" >
          <mat-card-content>
        <mat-list  style="text-transform:uppercase">

          <mat-list-item>Total inscritos: {{(item.totalHombreIncritos+ item.totalMujeresIncritas)}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>  Inscritos Hombres : {{item.totalHombreIncritos==null ? '0' : item.totalHombreIncritos}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Inscritos Mujeres: {{item.totalMujeresIncritas ==null ? '0' : item.totalMujeresIncritas }}</mat-list-item>
          <mat-divider></mat-divider>

         </mat-list>

         <mat-tab-group  style="text-transform:uppercase">
          <mat-tab label="Votos">
            <mat-toolbar style="background-color: #87CEEB;color: white;">
              <mat-toolbar-row>
                <span *ngIf="item.totalEmitidos">Votos totales: {{item.totalEmitidos}} votos</span>
                <span *ngIf="item.totalEmitidos==null">Votos totales: {{item.totalEmitidos}} votos</span>
              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.emitidosHombre!=null && item.totalHombreIncritos!=null">Votos hombres: {{item.emitidosHombre}} votos</span>
                <span *ngIf="item.emitidosHombre==null && item.totalHombreIncritos!=null">Votos hombres: 0 votos</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.emitidosMujer!=null && item.totalMujeresIncritas!=null">Votos mujeres: {{item.emitidosMujer}} votos</span>
                <span *ngIf="item.emitidosMujer==null && item.totalMujeresIncritas!=null">Votos mujeres: 0 votos</span>

              </mat-toolbar-row>
            </mat-toolbar>



          </mat-tab>
          <mat-tab label="Participación %">
            <mat-toolbar style="background-color: #8db600;color: white;">
              <mat-toolbar-row>
                <span *ngIf="item.porcentajeTotalEmitidos!=null">Participación Global: {{round(((item.emitidosHombre + item.emitidosMujer) * 100) /(item.totalHombreIncritos + item.totalMujeresIncritas))}}%</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.porcentajeEmitidosHombre!=null && item.totalHombreIncritos!=null">Participación hombres: {{round(item.porcentajeEmitidosHombre)}}%</span>
                <span *ngIf="item.porcentajeEmitidosHombre==null && item.totalHombreIncritos!=null">Participación hombres: 0%</span>

              </mat-toolbar-row>

              <mat-toolbar-row>
                <span *ngIf="item.porcentajeEmitidosMujer!=null && item.totalMujeresIncritas!=null">Participación mujeres: {{round(item.porcentajeEmitidosMujer)}}%</span>
                <span *ngIf="item.porcentajeEmitidosMujer==null && item.totalMujeresIncritas!=null">Participación mujeres: 0%</span>
              </mat-toolbar-row>
            </mat-toolbar>
          </mat-tab>

        </mat-tab-group>
        </mat-card-content>
        </mat-card>









    <br>

    <br>
    <br>




    <div class="chart_box" *ngIf="item.totalEmitidos > 0 ">
    <google-chart #chart
    [title]="title"
    [type]="type"
    [data]="[['Votos emitidos',item.totalEmitidos],['Votos no emitidos',(item.totalHombreIncritos+ item.totalMujeresIncritas) - item.totalEmitidos]]"
    [columns]="columnNames"
    [options]="options"
    >
  </google-chart>
</div>


<div class="chart_box" *ngIf="item.totalHombreIncritos!=null " >
  <google-chart #chart
  [title]="title3"
  [type]="type"
  [data]="[['Votos emitidos',  item.emitidosHombre  ],['Votos no emitidos', item.totalHombreIncritos- item.emitidosHombre]]"
  [columns]="columnNames"
  [options]="options"
  >
</google-chart>

</div>

<div class="chart_box" *ngIf="item.totalMujeresIncritas!=null " >
  <google-chart #chart
  [title]="title4"
  [type]="type"
  [data]="[['Votos emitidos',  item.emitidosMujer  ],['Votos no emitidos', item.totalMujeresIncritas - item.emitidosMujer]]"
  [columns]="columnNames"
  [options]="options"
  >
</google-chart>

</div>




      </mat-tab>

    </div>
  </mat-tab-group>







<div class="mat-elevation-z8" *ngIf="votosHora.length > 0 " >
  <br/>
  <br/>
  <h3 style="text-align: center;"><strong>Votos por hora</strong></h3>
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Horario  </th>
      <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Cantidad de votos</th>
      <td mat-cell *matCellDef="let element"> {{element.total}} </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
</div>
