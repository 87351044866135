import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-ver-votacion',
  templateUrl: './ver-votacion.component.html',
  styleUrls: ['./ver-votacion.component.css']
})
export class VerVotacionComponent implements OnInit {


  votacion : Votacion[] = [];
  form!: FormGroup;

  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {
    this.getVotacion();
    this.rescatarVotacionForm();
  }
  rescatarVotacionForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      descripcion : ['',[Validators.required]],
      fechaApertura: ['', [Validators.required]],
      fechaCierre: ['',[Validators.required]],
      maximoOpciones: ['',[Validators.required]],



    });
  }
  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }

  upload(id:string,event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPadron(id,formdata).subscribe(
      (res) =>{
        console.log(res);
        this.router.navigate(['/verVotacion'])

      },
      err => console.log(err)
     );
  }

  EnvioCorreo(){
    this.votacionService.enviarCorreoMasivo().subscribe((res) =>{
    console.log(res);
    Swal.fire('Exito', 'Correo enviados', 'info');
  },
  err => console.log(err)
 );
}


  uploadPapeleta(id:string,event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPapeleta(id,formdata).subscribe(
      (res) =>{
        console.log(res);
        this.router.navigate(['/verVotacion'])

      },
      err => console.log(err)
     );
  }
  novotante(id:number){
    this.votacionService.novotantes(id).subscribe(
      res => console.log(res),
      err=> console.log(err)
    )


  }
  uploadLogo(id:string,event : any){
    const image = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', image);
    this.votacionService.uploadFoto(id,formdata).subscribe(
      (res) =>{
        console.log(res);
        this.router.navigate(['/verVotacion'])

      },
      err => console.log(err)
     );
  }


  generaNss() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < charactersLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

  apertura(){
    this.router.navigate(['/apertura/'+this.generaNss()])
  }

  cierre(){
    this.router.navigate(['/cierre/'+ this.generaNss()])


  }
  editar(id:number){

    this.votacionService.editarVotacion(id,this.form.value).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/'])
    })
  }

}
