<main class="form-signin w-100 m-auto">

  <br>
  <br>
    <form [formGroup]="form" (submit)="submit()" >

      <h1 align="center" class="h3 mb-3 fw-normal">Ingrese sus Credenciales</h1>

      <div align="center" class="mb-3">
      <mat-form-field class="login-form">
        <mat-label>Usuario</mat-label>
        <input formControlName="username" matInput type="text"   placeholder="Ingrese su usuario">

      </mat-form-field>
      </div>

      <div align="center" class="mb-3">
        <mat-form-field class="login-form">
          <mat-label>Contraseña</mat-label>
        <input formControlName="password" type="password" matInput   placeholder="Ingrese su contraseña">
      </mat-form-field>
      </div>


      <div align="center" class="mb-3">

      <button  mat-raised-button style="background-color: #87CEEB;padding: 5px;"  type="submit"><img src="/assets/images/enter.png" alt="image">&nbsp;&nbsp;Ingresar&nbsp;&nbsp;</button>
    </div>
      <br>
      <br>
    </form>
  </main>
