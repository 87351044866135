import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-form-categoria',
  templateUrl: './form-categoria.component.html',
  styleUrls: ['./form-categoria.component.css']
})
export class FormCategoriaComponent implements OnInit {
  form!: FormGroup;
  idVotacion: string='';
  votacion : Votacion[] = [];
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService) { }

  ngOnInit(): void {

    this.rescatarCategoriaForm();
  }
  rescatarCategoriaForm() {
    this.form = this.formBuilder.group({
      texto : ['',[Validators.required]],
      detalle : ['',[Validators.required]],



    });
  }


  submitCategoriaForm(){
const id: number = this.activatedRoute.snapshot.params['id'];

this.votacionService.agregarCategoria(id,this.form.value).subscribe(
  res =>{
    console.log(res);
    this.router.navigate(['/'])

  },
  err => console.log(err)
);
  }
}
