import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from 'src/app/class/user';
import { VotacionService } from 'src/app/services/votacion.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  authenticated = false;
  hola=true;
  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };

  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,
    
    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {
    VotacionService.authEmitter.subscribe( authenticated => {
      this.authenticated =authenticated;

      if(this.hola==true){
      this.votacionService.user().subscribe(
        {
        next: (res: any) => {
         // this.message = `Hola ${res.first_name} ${res.last_name}`;
         console.log(res)
         this.user=res;
          VotacionService.authEmitter.emit(true);
          this.hola=false;
        },
        error : err => {
          //this.message =` Tu no estas autenticado`;
          VotacionService.authEmitter.emit(false);
        }
        });
      }
    });

    
  }

  
  logout(){
    this.votacionService.logout().subscribe( () => {
      this.votacionService.accessToken= '';
      VotacionService.authEmitter.emit(false);
      this.router.navigate(['/'],{
        skipLocationChange: true
      })
    }
    )
  }
}
