<div class="container-fluid">
  <mat-tab-group align="center"  style="text-transform:uppercase">
    <div class="row" align="center"  >

      <mat-tab  label="Resumen Papeleta:  {{ item.nombre }}" *ngFor="let item of votacion">

        <mat-card align="center">
          <mat-card-content>
        <mat-list  style="text-transform:uppercase">
          <mat-list-item>{{ item.nombre }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>{{item.descripcion}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Fecha Inicio: {{ item.fechaApertura | date:'yyyy-MM-dd HH:mm:ss' }}

          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            Fecha Termino:
            {{ item.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Nº máximo de preferencias: {{item.maximoOpciones}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Total inscritos: {{item.totalIncritos}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>  Inscritos Hombres : {{item.totalIncritosHombre==null ? '0' : item.totalIncritosHombre}}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>Inscritos Mujeres: {{item.totalIncritosMujer ==null ? '0' : item.totalIncritosMujer }}</mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item  style="text-transform:uppercase">{{item.estado+ ' - ' + estado}}</mat-list-item>
          <mat-divider></mat-divider>
         </mat-list>

        </mat-card-content>
      </mat-card>











  <br>

  <br>
  <br>
    </mat-tab>

  </div>
</mat-tab-group>

      <mat-divider></mat-divider>
    <br>
    <br>
    <br>
      <mat-card>
        <form >
            <br>
            <h2>Validación de credenciales</h2>
            <br>
        <div class="input-group mb-3">
            <input type="text" [(ngModel)]='usuMF' name="usuMF" id="usuMF" class="form-control" placeholder="Usuario Ministro" aria-label="Username">
            <span class="input-group-text">Usuario</span>
            <input type="password" [(ngModel)]='passMF' name="passMF" id="passMF" class="form-control" placeholder="Contraseña Ministro" aria-label="Server">
            <span class="input-group-text">Contraseña</span>
          </div>
          <div class="input-group mb-3">
            <input type="text" [(ngModel)]='usuCE' name="usuCE" id="usuCE" class="form-control" placeholder="Usuario CE" aria-label="Username">
            <span class="input-group-text">Usuario</span>
            <input type="password" [(ngModel)]='passCE' name="passCE" id="passCE" class="form-control" placeholder="Contraseña CE" aria-label="Server">
            <span class="input-group-text">Contraseña</span>
          </div>

          <button aria-disabled="true" mat-raised-button style="background-color: #87CEEB;" (click)="cierre()">Validar Credenciales</button>
        </form>
      </mat-card>

    </div>
