import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { Configuracion } from 'src/app/class/configuracion';
import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.css']
})
export class AperturaComponent implements OnInit {
  displayedColumns: string[] = ['nombre', 'Correo'];
  today = new Date();
  votacion : Votacion[] = [];
  estado='';
  user : User[] = [];
  config : Configuracion = {
    id:0,
   estado:'',
   fechaApertura:'',
   fechaCierre:''
      };
  autorizada : boolean = false;
  myDate = new Date();
  constructor(
    private formBuilder : FormBuilder,
    private router:Router,
    private http: HttpClient,
    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {
    this.getVotacion();
this.getUsers();
this.getTraerConfiguracion();

  }
  getUsers() {
    this.votacionService.MFCE().subscribe(
      res => this.user=res,
      err=> console.log(err)
    )
  }


  getTraerConfiguracion(){
    this.votacionService.verConfiguracion(1).subscribe( data  => {
   this.config=data;
   const [dateValues, timeValues] = this.config.fechaApertura.split(' ');

   const [dateValues2, timeValues2] = this.config.fechaCierre.split(' ');

console.log(dateValues);
   const [year,month, day] = dateValues.split('-');

   const [year2,month2, day2] = dateValues2.split('-');

   const [hours, minutes, seconds] = timeValues.split(':');

   const [hours2, minutes2, seconds2] = timeValues2.split(':');

   const date = new Date(
     +year,
     +month - 1,
     +day,
     +hours,
     +minutes,
     +seconds,
   );

   const date2 = new Date(
    +year2,
    +month2 - 1,
    +day2,
    +hours2,
    +minutes2,
    +seconds2,
  );


   //  👇️️ Sat Sep 24 2022 07:30:14

   if(date > this.today ){
    this.estado='no iniciada';
   }
   else{
    this.estado='en proceso';
   }

   if(this.today > date2 ){
     this.estado='proceso finalizado'
   }

  });

}
  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }
  generarPDF(){
    const doc = new jsPDF();
  doc.text("Acta de apertura",80,20);
    var logo = new Image();
logo.src = '../../../assets/images/VPTING_-removebg.png';

doc.addImage(logo, 'JPEG', 5, 5, 30, 30,undefined,'FAST');
    //const head = [['Opcion', 'Votos']]

    autoTable(doc, { html: '#estadistica',margin:40,theme:'grid',styles: { font : 'helvetica' ,
    fontStyle: 'bold',halign : 'center'}})


        autoTable(doc, { html: '#datos',margin:40,theme:'grid',styles: { font : 'helvetica' ,
      fontStyle: 'bold',halign : 'center'}})

      var firma = new Image();
      firma.src = '../../../assets/images/Signature.png';


doc.addImage(firma, 'JPEG', 70, 170, 80, 80,undefined,'FAST');

doc.text("Firma del representante legal: Luis Bravo",50,235);

    doc.save('documento de apertura'+'.pdf');
  }
  aperturar(){
    Swal.fire({
      title: 'Esta seguro?',
      text: "¿Desea aperturar?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        this.votacionService.aperturaryGenerarLLaves().subscribe(
          res => {
            this.autorizada=true;
            this.generarPDF();

            window.location.reload();
        },
          err=> console.log(err)
        )
      }
    })
  }

}
