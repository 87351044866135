import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-form-opcion',
  templateUrl: './form-opcion.component.html',
  styleUrls: ['./form-opcion.component.css']
})
export class FormOpcionComponent implements OnInit {

  form!: FormGroup;


  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService) { }

  ngOnInit(): void {

    this.rescatarCategoriaForm();
  }
  rescatarCategoriaForm() {
    this.form = this.formBuilder.group({
      texto : ['',[Validators.required]],
      descripcion : ['',[Validators.required]],



    });
  }


  submitCategoriaForm(){
const id: number = this.activatedRoute.snapshot.params['id'];

this.votacionService.agregarOpcion(id,this.form.value).subscribe(
  res =>{
    console.log(res);
    this.router.navigate(['/verOpciones/'+id])

  },
  err => console.log(err)
);
  }

}
