import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgregarConfiguracionComponent } from './pages/agregar-configuracion/agregar-configuracion.component';
import { AgregarVotacionComponent } from './pages/agregar-votacion/agregar-votacion.component';
import { AperturaComponent } from './pages/apertura/apertura.component';
import { MatCardModule } from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { CierreComponent } from './pages/cierre/cierre.component';
import { ClavesComponent } from './pages/claves/claves.component';
import { CrearUsuarioComponent } from './pages/crear-usuario/crear-usuario.component';
import { EditarCategoriaComponent } from './pages/editar-categoria/editar-categoria.component';
import { EstadisticasComponent } from './pages/estadisticas/estadisticas.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { GoogleChartsModule } from 'angular-google-charts';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { EstadisticasGrupoComponent } from './pages/estadisticas-grupo/estadisticas-grupo.component';
import { FormCategoriaComponent } from './pages/form-categoria/form-categoria.component';
import { FormOpcionComponent } from './pages/form-opcion/form-opcion.component';
import { FormUsuarioComponent } from './pages/form-usuario/form-usuario.component';
import { FormatoVotoComponent } from './pages/formato-voto/formato-voto.component';
import { LoginComponent } from './pages/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CrmInterceptor } from './interceptors/crm.interceptor';
import { FormComponent } from "./pages/login/form/form.component";
import { AuthenticatorComponent } from "./pages/login/authenticator/authenticator.component";
import { NoVotantesComponent } from './pages/no-votantes/no-votantes.component';
import { OpcionesComponent } from './pages/opciones/opciones.component';
import { PadronComponent } from './pages/padron/padron.component';
import { NavComponent } from "./components/nav/nav.component";
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ResultadoComponent } from './pages/resultado/resultado.component';
import { ResultadoRegionComponent } from './pages/resultado-region/resultado-region.component';
import { VerPapeletaVotacionComponent } from './pages/ver-papeleta-votacion/ver-papeleta-votacion.component';
import { VerResultadoComponent } from './pages/ver-resultado/ver-resultado.component';
import {  IonicModule }  from "@ionic/angular";
import { VerResultadoRegionComponent } from './pages/ver-resultado-region/ver-resultado-region.component';
import { VerUsuariosComponent } from './pages/ver-usuarios/ver-usuarios.component';
import { VerVotacionComponent } from './pages/ver-votacion/ver-votacion.component';
import {MatMenuModule} from '@angular/material/menu';
import { FooterComponent } from "./components/footer/footer.component";
import { HttpClientModule } from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [
    AppComponent,
    AgregarConfiguracionComponent,
    AgregarVotacionComponent,
    AperturaComponent,
    FormComponent,
    CategoriaComponent,
    CierreComponent,
    NavComponent,
    FooterComponent,
    ClavesComponent,
    CrearUsuarioComponent,
    EditarCategoriaComponent,
    EstadisticasComponent,
    EstadisticasGrupoComponent,
    FormCategoriaComponent,
    FormOpcionComponent,
    FormUsuarioComponent,
    FormatoVotoComponent,
    LoginComponent,
    AuthenticatorComponent,
    NoVotantesComponent,
    OpcionesComponent,
    PadronComponent,
    ReportesComponent,
    ResultadoComponent,
    ResultadoRegionComponent,
    VerPapeletaVotacionComponent,
    VerResultadoComponent,
    VerResultadoRegionComponent,
    VerUsuariosComponent,
    VerVotacionComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule,
    MatMenuModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    GoogleChartsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTabsModule,
    MatSelectModule,
    MatToolbarModule,
    FormsModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule
  ],
  providers: [{
    provide : HTTP_INTERCEPTORS,
    useClass : CrmInterceptor,
    multi : true
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
