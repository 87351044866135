import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { User } from 'src/app/class/user';
import { VotacionService } from 'src/app/services/votacion.service';

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ExporterService } from 'src/app/services/exporter.service';
import { Votacion } from 'src/app/class/votacion';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-no-votantes',
  templateUrl: './no-votantes.component.html',
  styleUrls: ['./no-votantes.component.css']
})
export class NoVotantesComponent implements OnInit {


  votanteRut : string='';

  votacion : Votacion[] = [];
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,

    private votacionService: VotacionService,
  ) {
    const rut: string = this.activatedRoute.snapshot.params['rut'];
    this.votacion  =[];

  }




  ngOnInit(): void {


    const rut: string = this.activatedRoute.snapshot.params['rut'];

    this.votanteRut=rut;
    console.log('ruttraido'+rut);


    this.votaciones(rut);



  }








  votaciones(rut:string) {
    this.votacionService.vervotacionesUsuario(rut).subscribe(
       res =>{ this.votacion=res;






      }
      ,
      err=> console.log(err)
    )
  }
}
