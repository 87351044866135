import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Regiones } from 'src/app/class/regiones';
import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-resultado-region',
  templateUrl: './resultado-region.component.html',
  styleUrls: ['./resultado-region.component.css']
})
export class ResultadoRegionComponent implements OnInit {
  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };
  votacion : Votacion[] = [];
  regiones: Regiones [] = [];
  region!: string;
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,

    private router : Router,

    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {
this.getRegiones(1);
    this.getVotacion();

    this.votacionService.user().subscribe(
      {
      next: (res: any) => {
       // this.message = `Hola ${res.first_name} ${res.last_name}`;
       console.log(res)
       this.user=res;
        VotacionService.authEmitter.emit(true);
      },
      error : err => {
        //this.message =` Tu no estas autenticado`;
        VotacionService.authEmitter.emit(false);
      }
      });
  }

  getRegiones(id:number){
      this.votacionService.Regiones(id).subscribe(
        res => this.regiones=res,
        err=> console.log(err)
      )
  }
  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
      res => this.votacion=res,
      err=> console.log(err)
    )
  }
  generarResultado(id :number){
    var DropdownList = (document.getElementById("regiones")) as HTMLSelectElement;



   // this.region = select.value;
    this.votacionService.generarResultadoRegion(id, DropdownList.value,null).subscribe(
      res =>
      this.router.navigate(['/verResultadoRegion/'+id+'/'+ DropdownList.value],{
        skipLocationChange: true
      })
      ,

      err=> console.log(err)
    )
  }
}
