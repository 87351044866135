import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  crear:boolean=false;
  loginData = {
    id:0,
    img: ''
  }
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private router:Router,


    private votacionService: VotacionService) { }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
    if(this.crear==false){
      this.crearAdmin();
    }
  }
  crearAdmin() {
    this.votacionService.crearAdmin().subscribe(res => {
           this.crear=true;
    },
    err => {
      console.log(err)
    })
  }
  onLogin(data: any){
    this.loginData = data;
    if(data.otpauth_url){
      //qrcode.toDataURL(data.otpauth_url, (err: any, img: string) => {
        //this.loginData.img=img;
      //})
    }
  }
}
