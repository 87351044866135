

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/class/user';

import { VotacionService } from 'src/app/services/votacion.service';
import { AppComponent } from './../../../app.component';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Output('onLogin') onLogin = new EventEmitter();
  form!: FormGroup;
   user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };
  constructor(
    private formBuilder : FormBuilder,

    private router : Router,
    private votacionService: VotacionService,

  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username : '' ,
      password: ''
    });




  }

  submit(){
   this.votacionService.login(this.form.getRawValue()).subscribe(
    (res: any) =>{       this.votacionService.accessToken = res.token;
     console.log('1',res.rol);
    VotacionService.authEmitter.emit(true);

    if(res.rol==='admin'){
    this.router.navigate(['/inicio']);
    }
    else{
      this.router.navigate(['/resultado']);
    }
    }
   );
  }



}
