<form [formGroup]="form">
  <table class="table">
      <thead>
        <tr>

          <th scope="col">Nombre</th>
          <th scope="col">Sindicato</th>
          <th scope="col">fechaApertura</th>
          <th scope="col">fechaCierre</th>
          <th scope="col">maximoOpciones</th>
          <th scope="col">Agregar Padron</th>
          <th scope="col">Agregar Papeleta</th>
          <th scope="col">Agregar Logo</th>
          <th scope="col">Editar Votación</th>
          <th scope="col">Formato de voto</th>
          <th scope="col">Apertura y Cierre</th>
          <th scope="col">Ver papeleta</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ex of votacion">
          <td>
            <input type="text" formControlName="nombre"  name="nombre"  class="form-control"  placeholder="Nombre Votación" autofocus>
            <span>{{ex.nombre}}</span>
          </td>
          <td>
            <input type="text" formControlName="descripcion"  name="descripcion"  class="form-control"  placeholder="Nombre Sindicato" autofocus>
            <span>{{ex.descripcion}}</span>
          </td>
          <td>
            <input type="datetime-local" formControlName="fechaApertura"  name="fechaApertura"  class="form-control"  placeholder="Fecha Apertura">
           <span>{{ex.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</span> </td>
          <td>
            <input type="datetime-local"  formControlName="fechaCierre" name="fechaCierre"  class="form-control"  placeholder="Fecha Cierre">
          <span>{{ex.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</span>  </td>
          <td>
            <input type="number" formControlName="maximoOpciones"  name="maximoOpciones"  class="form-control"  placeholder="maximoOpciones" autofocus>
           <span>{{ex.maximoOpciones}}</span>
          </td>
          <td>  <input type="file"  name="file" (change)="upload(ex.id.toString(),$event)"   class="form-control"  placeholder="file" >
              <label for="floatingInput">Padron</label></td>
              <td>  <input type="file"  name="file" (change)="uploadPapeleta(ex.id.toString(),$event)"   class="form-control"  placeholder="file2" >
                  <label for="floatingInput">Papeleta</label>

                </td>

                  <td>  <input type="file"  name="file" (change)="uploadLogo(ex.id.toString(),$event)"   class="form-control"  placeholder="file2" >
                    <label for="floatingInput">Imagen</label></td>


                    <td>
                      <button   class="w-100 btn btn-lg btn-primary" (click)="editar(ex.id)"  type="submit">Editar Votacion</button>
                     </td>
                   <td>
                    <a   class="w-100 btn btn-lg btn-primary" routerLink="/formatovoto/{{ex.id}}"   type="submit">Ver Formato de Voto</a>
                   </td>
                  <td>   <button   class="w-100 btn btn-lg btn-primary" (click)="apertura()"  type="submit">Apertura</button>
                    <br>
                    <br>
                    <button   class="w-100 btn btn-lg btn-primary" (click)="cierre()"  type="submit">Cierre</button>
                    </td>
                    <td>
                      <a   class="w-100 btn btn-lg btn-primary" routerLink="/crearCategoria/{{ex.id}}"   type="submit">Crear Papeleta</a>
                      <br>
                      <br>
                      <a   class="w-100 btn btn-lg btn-primary" routerLink="/categoria/{{ex.id}}"   type="submit">Ver Papeleta</a>
                      </td>
        </tr>

      </tbody>
    </table>

  </form>

  <button   class="w-100 btn btn-lg btn-primary" (click)="EnvioCorreo()"  >Recordatorio Para Votar</button>
