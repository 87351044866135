import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from 'src/app/class/user';
import { VotacionService } from 'src/app/services/votacion.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-agregar-votacion',
  templateUrl: './agregar-votacion.component.html',
  styleUrls: ['./agregar-votacion.component.css']
})
export class AgregarVotacionComponent implements OnInit {

  form!: FormGroup;


fechaApertura = new Date();
fechaCierre = new Date();
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,

    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService
  ) { }

  ngOnInit(): void {

    this.rescataVotacionForm();
  }
  rescataVotacionForm() {
    this.form = this.formBuilder.group({
      nombre : ['',[Validators.required]],
      descripcion : ['',[Validators.required]],
      fechaApertura: ['', [Validators.required]],
      fechaCierre: ['',[Validators.required]],
      maximoOpciones: ['',[Validators.required]],



    });
  }
  submitVotacionForm() {

    if (this.form.valid){

      if(this.fechaCierre> this.fechaApertura){
    this.votacionService.agregarVotacion(this.form.value).subscribe(
      res =>{
        console.log(res);
        this.router.navigate(['/'],{
          skipLocationChange: true})

      },
      err => console.log(err)
    );
      }
      else{
        Swal.fire('Atencion', 'la fecha de apertura no puede ser mayor a la fecha de cierre!', 'info');
      }
    }
  else{
    Swal.fire('Atencion', 'Formulario con errores!', 'info');
  }



  }

}
