
<table class="table">
  <thead>
    <tr>

      <th scope="col">Nombre</th>
      <th>Grupos</th>
      <th scope="col">Incritos</th>
      <th scope="col">No Votantes</th>
      <th scope="col">Votantes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ex of votacion">
      <td>{{ex.nombre}}</td>
<td><select  name="regiones" id="regiones" >
<option *ngFor="let rr of regiones" [value]="rr.regiones">
  {{rr.regiones}}
</option>
</select>

<button   aria-disabled="true" mat-raised-button color="accent" (click)="novotanteRegion(ex.id,ex.nombre)"  type="submit">Generar No Votantes Grupo</button>
</td>
<td>
  <button (click)="incritos(ex.id,ex.nombre)" mat-raised-button color="accent"  style="margin: 10px;"
  ><span
  class="glyphicon glyphicon-list-alt"></span> Reporte socios
  inscritos XLS</button>
</td>
              <td>   <button   aria-disabled="true" mat-raised-button color="accent" (click)="novotante(ex.id,ex.nombre)"  type="submit">Generar No Votantes</button>
                </td>

                <td>   <button   aria-disabled="true" mat-raised-button color="accent" (click)="votantes(ex.id,ex.nombre)"  type="submit">Generar Votantes</button>
                </td>
    </tr>

  </tbody>
</table>

