import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Categoria } from 'src/app/class/categoria';
import { VotacionService } from 'src/app/services/votacion.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {
  form!: FormGroup;

  categoria : Categoria = {
    id:0,
    texto: '',
    detalle: ''
  }
  constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private activatedRoute: ActivatedRoute,
    private router : Router,
    private votacionService: VotacionService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    const id: string = this.activatedRoute.snapshot.params['id'];
    this.categorias(Number(id));
    this.rescatarCategoriaForm();
  }
  rescatarCategoriaForm() {
    this.form = this.formBuilder.group({
      texto : ['',[Validators.required]],
      detalle : ['',[Validators.required]],



    });
  }
  categorias(id: number) {
    this.votacionService.getCategoria(id).subscribe( (res) =>{
      console.log(res);
      this.categoria=res;


    },
    err => console.log(err)
   );
  }

  editar(id:number){

    this.votacionService.editarCategoria(id,this.form.value).subscribe((res) => {
      console.log(res);

      this.router.navigate(['/verVotacion'])
    })
  }


}
