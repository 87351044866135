import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";


import * as FileSaver from 'file-saver';
import * as XlSX from 'xlsx';

const EXCEL_TYPE=
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXT= '.xlsx';

@Injectable({
    providedIn: 'root'
  })
export class ExporterService{

    constructor() { }

    exportToExcel(json:any[],excelFileName: string): void{
        const worksheet : XlSX.WorkSheet = XlSX.utils.json_to_sheet(json);
        const workbook : XlSX.WorkBook = { Sheets: {'data' : worksheet},
    SheetNames:['data']
    };
    const excelBuffer: any = XlSX.write(workbook, {bookType : 'xlsx' , type: 'array'});

    this.saveAsExcel(excelBuffer, excelFileName);
    }
    saveAsExcel(buffer: any, fileName: string): void {
       const data: Blob = new Blob([buffer], {type : EXCEL_TYPE});
       FileSaver.saveAs(data,fileName + '_export_' +  new Date().getTime() + EXCEL_EXT);
    }



}
