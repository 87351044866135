<div style="text-align:center ;">
  <form [formGroup]="form">
      <br>
      <h3>Crear Votante</h3>
      <br>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Nombre</label>
        <input type="text" formControlName="nombre"  name="nombre" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

      </div>
      <div class="mb-3">
        <label for="exampleInputPassword1" class="form-label">Apellido</label>
        <input type="text" formControlName="apellido"  name="apellido" class="form-control" id="exampleInputPassword1">
      </div>
      <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Rut</label>
          <input type="text" formControlName="rut" oninput="checkRut(this)"  name="rut" class="form-control" id="exampleInputPassword1">
        </div>
      <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Correo</label>
          <input type="email" formControlName="email"  name="email" class="form-control" id="exampleInputPassword1">
        </div>


        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Genero</label>
          <select class="form-select" formControlName="genero"  name="genero" aria-label="Default select example">
              <option selected>Seleccione</option>
              <option value="M">Hombre</option>
              <option value="F">Mujer</option>
            </select>
        </div>

        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Region o Grupo</label>
          <input type="text" formControlName="grupo"  name="grupo" class="form-control" id="exampleInputPassword1">
        </div>
      <button type="submit" (click)="submitUsuarioForm()" class="btn btn-primary">Guardar</button>
    </form>

  </div>
