import { Component,  OnInit} from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { VotacionService } from 'src/app/services/votacion.service';
import { ExporterService } from 'src/app/services/exporter.service';
import { Votacion } from 'src/app/class/votacion';

import { Router } from '@angular/router';
import { Configuracion } from 'src/app/class/configuracion';
import { ChartType, GoogleChartsModule } from 'angular-google-charts';
import { VotoHora } from 'src/app/class/votoHora';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { Resultado } from 'src/app/class/resultado';
@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css']
})
export class EstadisticasComponent  implements OnInit  {


  displayedColumns: string[] = ['position', 'name', ];
  dataSource! : MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator ;


  config : Configuracion = {
    id:0,
   estado:'',
   fechaApertura:'',
   fechaCierre:''
      };

      resultado : Resultado = {
        id: 0,
        emitidosHombre: 0,
        emitidosMujer: 0,
        noEmitidosHombre: 0,
        noEmitidosMujer : 0,
        porcentajeEmitidosHombre: 0,
        porcentajeEmitidosMujer: 0,
        porcentajenoEmitidosHombre: 0,
        porcentajenoEmitidosMujer: 0,
        porcentajepreferenciaBlanco: 0,
        porcentajepreferenciaNulas: 0,
        porcentajepreferenciasNormal: 0,
        porcentajepreferenciasNoUtilizadaNoVotante: 0,
        porcentajeTotal:0,
        porcentajeTotalEmitidos: 0,
        porcentajetotalHombreIncritas:0,
        porcentajetotalMujeresIncritas: 0,
        porcentajeTotalNoEmitido: 0,
        porcentajetotalPrefereciasPosibles: 0,
        preferenciaBlanco: 0,
        preferenciaNulas: 0,
        preferenciasNormal: 0,
        preferenciasNoUtilizadaNoVotante: 0,
        totalEmitidos:0 ,
        totalHombreIncritos: 0,
        totalIncritos: 0,
        totalMujeresIncritas: 0,
        totalNoEmitidos: 0,
        totalPrefereciasPosibles:0
      }

  private datos : any =[];
   hola =0;
   polo=0;

  votacion : Votacion[] = [];
  votosHora : Array<VotoHora> = [];
  pipe = new DatePipe('en-US');


  today = new Date();
estado='';


  fechaActual= this.pipe.transform(this.today,'yyyy-MM-dd HH:mm:ss')
	/**
	* Constructor
	*/

  data :any=[];
  votos:any=[];
  data2: any=[];
  papeletaA:any = '';
  dataPapeleta:any = {};
  p1:any=[];
  p2:any=[];
  title = 'Votos emitidos vs Votos no emitidos';
  title2 = 'Votos no emitidos hombres vs Votos no emitidos mujeres';

  title3 = 'Votos emitidos hombres vs Votos no emitidos hombres';
  title4 = 'Votos emitidos mujeres vs Votos no emitidos mujeres';
  type = ChartType.PieChart;
  type2 = ChartType.Bar;
  chartData = [];
  columnNames = ['Votos', 'Porcentaje'];

  columnNames2 = ['1'];
  options = {
    'width':'100%',
                       'height':500,
                       'curveType': "function",

                       'chartArea': {'width': '98%'},
                       'axisTitlesPosition': 'in',
                       'vAxis': {'textPosition': 'in'},


  };
  width = 800;
  height = 400;

	constructor(private formBuilder : FormBuilder,
    private http: HttpClient,

    private router:Router,
    private votacionService: VotacionService,
    private exporterService: ExporterService) {

	}


  ngOnInit(): void {

    setTimeout(() => {
      this.getVotacion();
    },0);

    this.getTraerConfiguracion();
   this.getVotosHora(1);



  }

  getVotosHora(id:number):void{
    this.votacionService.votosPorHora(id).subscribe( votacion => {
      this.votosHora=votacion;
      this.dataSource=new MatTableDataSource(this.votosHora);
      this.dataSource.paginator = this.paginator;

    }
    );
  }

  getPapeleta(){
    for (let index = 0; index <  this.data.length; index++) {
      if (this.papeletaA==this.data[index].id){
        this.dataPapeleta = this.data[index];
        this.getVotosHora(this.data[index].id);

        console.log('que id traigo',this.data[index].id);
          this.votacionService.generarResultado(this.data[index].id,null).subscribe(
            res => {
              this.votacionService.getResultado(this.data[index].id).subscribe(
                res =>{ this.resultado=res;
          console.log(res);

                }
                ,
                err=> console.log(err)
              )
            },

            err=> console.log(err)
          )
        this.data2.length=0;

      }

    }

  }





  getTraerConfiguracion(){
    this.votacionService.verConfiguracion(1).subscribe( data  => {
   this.config=data;
   const [dateValues, timeValues] = this.config.fechaApertura.split(' ');

   const [dateValues2, timeValues2] = this.config.fechaCierre.split(' ');

console.log(dateValues);
   const [year,month, day] = dateValues.split('-');

   const [year2,month2, day2] = dateValues2.split('-');

   const [hours, minutes, seconds] = timeValues.split(':');

   const [hours2, minutes2, seconds2] = timeValues2.split(':');

   const date = new Date(
     +year,
     +month - 1,
     +day,
     +hours,
     +minutes,
     +seconds,
   );

   const date2 = new Date(
    +year2,
    +month2 - 1,
    +day2,
    +hours2,
    +minutes2,
    +seconds2,
  );


   //  👇️️ Sat Sep 24 2022 07:30:14
   console.log('dd',date);
   console.log('d2',date2);
   console.log(this.today);
   if(date > this.today ){
    this.estado='no iniciada';
   }
   else{
    this.estado='en proceso';
   }

   if(this.today > date2 ){
     this.estado='proceso finalizado'
   }

  });

}

round(valor:number){
  return Math.round(valor);
}

  getVotacion() {
    this.votacionService.verVotaciones().subscribe(
       (data:any) => {

      //  this.chartData = [];

       this.data=[];
        for(let index = 0; index < data.length; index++){

          if (index==0){
            this.papeletaA = data[index].id;
            this.dataPapeleta =  data[index];

          }
          this.data.push(data[index])




        }

        this.votacionService.generarResultado(1,null).subscribe(
          res => {
            this.votacionService.getResultado(1).subscribe(
              res =>{ this.resultado=res;
        console.log(res);

              }
              ,
              err=> console.log(err)
            )
          },

          err=> console.log(err)
        )

      });



  }








}
