<mat-toolbar style="background-color: #87CEEB;">



      <button *ngIf="authenticated && user.rol=='admin'" mat-button [matMenuTriggerFor]="menu">Menu Admin</button>
      <mat-menu #menu="matMenu">
        <a routerLink="/agregarVotacion" skipLocationChange=true mat-menu-item>Agregar Votacion</a>
        <a routerLink="/agregarConfiguracion" skipLocationChange=true mat-menu-item>Agregar Configuracion</a>
        <a routerLink="/verVotacion" skipLocationChange=true mat-menu-item>Ver Votaciónes</a>
        <a routerLink="/crearUsuario" skipLocationChange=true mat-menu-item>Crear Usuario</a>
        <a routerLink="/crearVotante" skipLocationChange=true mat-menu-item>Crear Votante</a>
        <a routerLink="/verUsuarios" skipLocationChange=true mat-menu-item>Ver Usuarios</a>
        <a routerLink="/padron" skipLocationChange=true mat-menu-item>Validar Padron</a>
       <a routerLink="/resultado" skipLocationChange=true mat-menu-item>Resultado</a>
       <a routerLink="/resultadoRegion" skipLocationChange=true mat-menu-item>Resultado Grupo</a>
        <a routerLink="/reportes" skipLocationChange=true mat-menu-item>Reportes</a>
        <a routerLink="/clavesrr" skipLocationChange=true mat-menu-item>CLAVES CE</a>
      </mat-menu>



      <button *ngIf="authenticated && user.rol!='admin'  " mat-button [matMenuTriggerFor]="menuMFCE">Menu MFCE</button>
      <mat-menu #menuMFCE="matMenu">
        <a routerLink="/resultado" skipLocationChange=true mat-menu-item>Resultado</a>
        <a routerLink="/reportes" skipLocationChange=true mat-menu-item>Reportes</a>
        <a routerLink="/resultadoRegion" skipLocationChange=true mat-menu-item>Resultado Grupo</a>
      </mat-menu>

      <span class="menu-espasivo"></span>

      <button *ngIf="authenticated" (click)="logout()" mat-icon-button class="example-icon" aria-label="Example icon-button with heart icon">
      Salir
      </button>
      <button *ngIf="!authenticated" mat-button [matMenuTriggerFor]="animals"><strong>Menu</strong></button>
      <mat-menu #animals="matMenu">

        <a mat-menu-item *ngIf="!authenticated" routerLink="/estadisticas" skipLocationChange=true > <strong>Estadisticas&nbsp;</strong>


        </a>

     <!--   <a mat-menu-item *ngIf="!authenticated" routerLink="/estadisticas-grupo" skipLocationChange=true > <strong>Estadisticas Region&nbsp;</strong>
          <img src="/assets/images/pie-chart.png" alt="image">

        </a>
 -->
        <a mat-menu-item *ngIf="!authenticated" routerLink="/login" skipLocationChange=true ><strong>Login&nbsp;</strong>


        </a>
      </mat-menu>





</mat-toolbar>
