import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import { Votacion } from "../class/votacion";
import { User } from "../class/user";
import { Opcion } from "../class/opcion";
import { Categoria } from "../class/categoria";
import { Voto } from "../class/voto";
import { Resultado } from "../class/resultado";
import { Configuracion } from "../class/configuracion";
import { tap } from 'rxjs/operators';
import { OpcionResultado } from "../class/opcionResultado";
import { VotoHora } from "../class/votoHora";
import { Regiones } from "../class/regiones";


@Injectable({
    providedIn: 'root'
  })
export class VotacionService{

  private _refresh$ = new Subject<void>();
  static authEmitter = new EventEmitter<boolean>();

  get resfresh$(){
    return this._refresh$;
  }

  accessToken = '';

    constructor( private http: HttpClient) { }


    login(body:any){
      return  this.http.post( `${environment.api}user/login`,body);
     }

     authenticatorLogin(body:any){
      return  this.http.post( `${environment.api}user/two-factor`,body,{withCredentials: true});
     }

     refresh(){
      return  this.http.post( `${environment.api}user/refresh`, {},{withCredentials: true});
     }
     user(){
      return  this.http.get( `${environment.api}user/usuario`);
     }

     logout(){
      return  this.http.post( `${environment.api}user/logout`, {},{withCredentials: true});
     }

    agregarVotacion(body:any){
        return  this.http.post( `${environment.api}votacion`,body);
       }

       verConfiguracion(id:number){
        return  this.http.get<Configuracion>( `${environment.api}configuracion/${id}`,{headers:{skip:"true"}} );
       }

       agregarConfiguracion(body:any){
        return  this.http.post( `${environment.api}configuracion/crear`,body,{headers:{skip:"true"}});
       }

       agregarCategoria(id:number,body:any){
        return  this.http.post( `${environment.api}categoria/createCategoria/${id}`,body);
       }

       agregarOpcion(id:number,body:any){
        return  this.http.post( `${environment.api}opcion/createOpcion/${id}`,body);
       }


       verUsuario(rut: string):Observable<User>{
        return this.http.get<User>(`${environment.api}user/usuarioRut/${rut}`);
       }

       traerFtoo(idFoto: string):Observable<any>{
        return this.http.get<User>(`${environment.api}/image/${idFoto}`);
       }

       verVotaciones():Observable<Votacion[]>{
        return  this.http.get<Votacion[]>( `${environment.api}votacion`,{headers:{skip:"true"}});
       }

       VotacionesUsuDELO(rut:string):Observable<Votacion[]>{
        return  this.http.get<Votacion[]>( `${environment.api}votacion/votacionesDelUsuario/${rut}`,{headers:{skip:"true"}});
       }

       verVotacion(id: number):Observable<Votacion>{
        return this.http.get<Votacion>(`${environment.api}votacion/${id}`,{headers:{skip:"true"}});
       }

       enviarReportes(id: number, escrutinio :string,incritos :string
        ,votante :string,novotante :string,apertura :string
       ){
        return this.http.get(`${environment.api}votacion/envioResultados/${id}/${escrutinio}/${incritos}/${votante}/${novotante}/${apertura}`,{headers:{skip:"true"}});
       }


       uploadPadron(id: string,body:any){
        return  this.http.post( `${environment.api}votacion/upload${id}`,body);
       }

       uploadPapeleta(id: string,body:any){
        return  this.http.post( `${environment.api}categoria/uploadPapeleta${id}`,body);
       }

       uploadFoto(id: string,body:any){
        return  this.http.post( `${environment.api}opcion/imagen${id}`,body,{headers:{skip:"true"}});
       }

       uploadPDF(body:any){
        return  this.http.post( `${environment.api}opcion/carga`,body,{headers:{skip:"true"}});
       }

       uploadFotoGoogleCloud(id: string,body:any){
        return  this.http.post( `${environment.api}media/subir/${id}`,body,{headers:{skip:"true"}});
       }

       uploadFotoOpcion(id: number,body:any){
        return  this.http.post( `${environment.api}categoria/imagen${id}`,body,{headers:{skip:"true"}});
       }

       autenticacionSf(body:User){
        return  this.http.post( `${environment.api}votacion/autenticacionSF`,body,{headers:{skip:"true"}});
       }

       vervotacionesUsuario(rut: string):Observable<Votacion[]>{
        return this.http.get<Votacion[]>(`${environment.api}votacion/votacionUsuario/${rut}`,{headers:{skip:"true"}})
        .pipe(
          tap(() => {
          this._refresh$.next();
        })
        );
       }

       vervotacionesUsuario2(rut: string):Observable<any[]>{
        return this.http.get<any[]>(`${environment.api}votacion/votacionUsuario3/${rut}`,{headers:{skip:"true"}});
       }


       votarOpciones(rut: string,id:string):Observable<Opcion[]>{
        return this.http.get<Opcion[]>(`${environment.api}votacion/votar/${rut}/${id}`,{headers:{skip:"true"}});
       }

       getCategoria(id: number){
        return this.http.get<Categoria>(`${environment.api}categoria/ver/${id}`,{headers:{skip:"true"}});
       }

       voto(rut:string,id: number,seleccionada: number ,opcion: number[]){
        return  this.http.post( `${environment.api}votacion/votoNormal/${rut}/${id}/${seleccionada}/${opcion}`,null,{headers:{skip:"true"}});
       }

       votoBlanco(rut:string,id: number,body:any){
        return this.http.post( `${environment.api}votacion/votoBlancos/${rut}/${id}`,body,{headers:{skip:"true"}});
       }
       votoNulo(rut:string,id: number,body:any){
        return this.http.post( `${environment.api}votacion/votoNulo/${rut}/${id}`,body,{headers:{skip:"true"}});
       }

       votosUsuario(rut:string){
        return this.http.get<Voto[]>(`${environment.api}votacion/votosUsuario/${rut}`,{headers:{skip:"true"}});
       }
       votosUsuarioConsulta(rut:string){
        return this.http.get<Voto[]>(`${environment.api}votacion/votoUsuarioCosulta/${rut}`,{headers:{skip:"true"}});
       }

       Regiones(id:number){
        return this.http.get<Regiones[]>(`${environment.api}estadisticas-region/regiones/${id}`,{headers:{skip:"true"}});
       }

       generarResultado(id:number,body:any){
        return this.http.post( `${environment.api}resultado/crear/${id}`,body);
       }

       generarResultadoRegion(id:number,grupo:string,body:any){
        return this.http.post( `${environment.api}estadisticas-region/crearRegion/${id}/${grupo}`,body);
       }

       getResultado(id:number){
        return this.http.get<Resultado>(`${environment.api}resultado/resultadoTraido/${id}`);
       }

       getResultadoRegion(id:number,grupo:string){
        return this.http.get<Resultado>(`${environment.api}estadisticas-region/resultadoTraido/${id}/${grupo}`);
       }

       getOpcionesCategoria(id:number){
        return this.http.get<Opcion[]>(`${environment.api}opcion/verOpciones/${id}`,{headers:{skip:"true"}});
       }

       generarPDf2(id:number){
        return this.http.get(`${environment.api}resultado/generarPdf/${id}`,{headers:{skip:"true"}});
       }

       excel(){
        return this.http.get(`${environment.api}resultado/dowload`,{headers:{skip:"true"}});
       }

       novotantes(id:number){
        return this.http.get<User[]>(`${environment.api}resultado/novotante/${id}`,{headers:{skip:"true"}});
       }

       claves(){
        return this.http.get<User[]>(`${environment.api}resultado/clavesrr`,{headers:{skip:"true"}});
       }

       novotantesRegion(id:number,grupo:string){
        return this.http.get<User[]>(`${environment.api}resultado/novotanteRegion/${id}/${grupo}`,{headers:{skip:"true"}});
       }

       votosPorHora(id:number){
        return this.http.get<VotoHora[]>(`${environment.api}votacion/votosPorHora/${id}`,{headers:{skip:"true"}});
       }

       votosPorHoraRegion(id:number,grupo:string){
        return this.http.get<VotoHora[]>(`${environment.api}votacion/votosPorHoraRegion/${id}/${grupo}`,{headers:{skip:"true"}});
       }

       OpcionResultado(id:number){
        return this.http.get<OpcionResultado[]>(`${environment.api}opcion/OpcionResultado/${id}`,{headers:{skip:"true"}});
       }

       OpcionResultadoRegion(id:number,grupo:string){
        return this.http.get<OpcionResultado[]>(`${environment.api}opcion/OpcionResultadoGrupo/${id}/${grupo}`,{headers:{skip:"true"}});
       }


       crearMFYcE(body:any){
        return  this.http.post(`${environment.api}user/crearMfCe`,body);
       }

       crearVotante(body:any){
        return  this.http.post(`${environment.api}user/crearVotante`,body);
       }

       MFCE(){
        return this.http.get<User[]>(`${environment.api}user/MFCE`);
       }

       aperturaryGenerarLLaves(){
        return this.http.get(`${environment.api}user/aperturarClaves`,{headers:{skip:"true"}});
       }

       cierreVotacion(usuMF:string,passMF:string,usuCE:string,passCE:string){
        return this.http.get(`${environment.api}user/cierre/${usuMF}/${passMF}/${usuCE}/${passCE}`,{headers:{skip:"true"}});
       }

       ministro(){
        return this.http.get<User>(`${environment.api}votacion/rr`);
       }

       uploadPadronRut(body:any){
        return  this.http.post( `${environment.api}votacion/validatePadron`,body);
       }

       uploadPadronGenero(body:any){
        return  this.http.post( `${environment.api}votacion/validatePadronGenero`,body);
       }

       uploadPadronEmail(body:any){
        return  this.http.post( `${environment.api}votacion/validatePadronEmail`,body);
       }

       uploadPadronRepetidos(body:any){
        return  this.http.post( `${environment.api}votacion/validatePadronRutRepetidos`,body);
       }
       votantes(id:number){
        return this.http.get<User[]>(`${environment.api}votacion/votante/${id}`);
       }

       votantesRegion(id:number,grupo:string){
        return this.http.get<User[]>(`${environment.api}votacion/votanteRegion/${id}/${grupo}`);
       }

       incritos(id:number){
        return this.http.get<User[]>(`${environment.api}votacion/inscrito/${id}`);
       }

       incritosRegion(id:number,grupo:string){
        return this.http.get<User[]>(`${environment.api}votacion/inscritoRegion/${id}/${grupo}`);
       }

       enviarCorreoMasivo(){
        return this.http.get<User[]>(`${environment.api}votacion/enviarCorreos`);
       }

       eliminarOpcion(id:number){
        return this.http.delete<Opcion>(`${environment.api}opcion/${id}`);
       }

       eliminarCategoria(id:number){
        return this.http.delete<Categoria>(`${environment.api}categoria/${id}`);
       }

       VerUsuariosClaves(){
        return this.http.get<User[]>(`${environment.api}user/verUsuariosAdmin`);
       }

       crearAdmin(){
        return this.http.get<User>(`${environment.api}votacion/buscarAdmin`);
       }

       hackerEntrando(rut:string,id: number){
        return this.http.get<Voto>(`${environment.api}votacion/comprobarVotoHombre/${rut}/${id}`);
       }

       eliminarUsuario(id:number){
        return this.http.delete<User>(`${environment.api}user/${id}`);
       }

       editarUsuario(id:number,body:any){
        return this.http.post<User>(`${environment.api}user/editUsuarios/${id}`,body);
       }
       editarVotacion(id:number,body:any){
        return this.http.post<Votacion>(`${environment.api}votacion/editVotacion/${id}`,body);
       }
       editarCategoria(id:number,body:any){
        return this.http.post<Categoria>(`${environment.api}categoria/editCategoria/${id}`,body);
       }
       editarOpcion(id:number,body:any){
        return this.http.post<Opcion>(`${environment.api}opcion/editOpcion/${id}`,body);
       }

}
