<table  class="table table-bordered border-primary" style="text-align: center;">
  <thead>
    <tr>

      <th scope="col">Nombre</th>
      <th scope="col">Cliente</th>
      <th scope="col">fechaApertura</th>
      <th scope="col">fechaCierre</th>
      <th scope="col">maximoOpciones</th>
      <th scope="col">Formato de voto</th>
      <th scope="col">Consultar Resultados</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ex of votacion">
      <td>{{ex.nombre}}</td>
      <td>{{ex.descripcion}}</td>
      <td>{{ex.fechaApertura | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      <td>{{ex.fechaCierre | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      <td>{{ex.maximoOpciones}}</td>

      <td>
        <a   class="w-100 btn btn-lg btn-primary" routerLink="/formatovoto/{{ex.id}}"   type="submit">Ver Formato de Voto</a>
       </td>
      <td>
          <button *ngIf="ex.estado=='escrutinio'"  class="btn btn-info"  (click)="generarResultado(ex.id)"
          ><span class="glyphicon glyphicon-list-alt"></span>CONSULTA RESULTADOS</button>
          <label *ngIf="ex.estado!='escrutinio' && user.rol!='admin'">Votación aun no se ha realizado el cierre</label>
          <button *ngIf="user.rol=='admin'"  class="btn btn-info"  (click)="generarResultado(ex.id)"
          ><span class="glyphicon glyphicon-list-alt"></span>CONSULTA RESULTADOS</button>
          </td>
    </tr>

  </tbody>
</table>
