import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ExporterService } from 'src/app/services/exporter.service';
import { VotacionService } from 'src/app/services/votacion.service';

@Component({
  selector: 'app-padron',
  templateUrl: './padron.component.html',
  styleUrls: ['./padron.component.css']
})
export class PadronComponent implements OnInit {


  myDataArray : any[]=[]
  myDataArray2 : any[]=[]
  myDataArray3 : any[]=[]
  myDataArray4 : any[]=[]
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,


    private votacionService: VotacionService,
    private exporterService: ExporterService) { }

  ngOnInit(): void {
  }


  validarPadronRuts(event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPadronRut(formdata).subscribe(
      (res) =>{
        console.log(res);
        this.myDataArray.push(res);
        this.exporterService.exportToExcel(this.myDataArray,'ruts_invalidos_'+this.myDataArray.length)

        this.myDataArray.length=0;

      },
      err => console.log(err)
     );
  }

  validarPadronGenero(event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPadronGenero(formdata).subscribe(
      (res) =>{
        console.log(res);
        this.myDataArray2.push(res);
        this.exporterService.exportToExcel(this.myDataArray2,'genero_invalidos_'+this.myDataArray2.length)

        this.myDataArray2.length=0;

      },
      err => console.log(err)
     );
  }


  validarPadronRepetidos(event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPadronRepetidos(formdata).subscribe(
      (res) =>{
        console.log(res);
        this.myDataArray3.push(res);
        this.exporterService.exportToExcel(this.myDataArray3,'ruts_repetidos_'+this.myDataArray3.length)

        this.myDataArray3.length=0;

      },
      err => console.log(err)
     );
  }

  validarPadronEmail(event : any){
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append('file', file);
    this.votacionService.uploadPadronEmail(formdata).subscribe(
      (res) =>{
        console.log(res);
        this.myDataArray4.push(res);
        this.exporterService.exportToExcel(this.myDataArray4,'email_malos_'+this.myDataArray4.length)

        this.myDataArray4.length=0;

      },
      err => console.log(err)
     );
  }
}
