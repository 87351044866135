<form [formGroup]="form">
  <table class="table">
      <thead>
        <tr>
          <th scope="col">Texto</th>
          <th scope="col">Descripcion</th>
          <th scope="col">Imagen</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ex of opcion">
         <td>
          <input type="text" formControlName="texto"  name="texto" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
        <span> {{ex.texto}} </span>

        </td>
         <td>
          <input type="text" formControlName="descripcion"  name="descripcion" class="form-control" id="exampleInputPassword1">
        <span> {{ex.descripcion}}</span>

        </td>
         <td><div mat-card-avatar  *ngIf="ex.foto!='.'"><img style="width: 50px;" src="{{url}}media/{{ex.foto}}" /></div>
          <br>
          <br>
          <input type="file"  name="file" (change)="uploadLogo(ex.id,$event)"   class="form-control"  placeholder="file2" >
          <label for="floatingInput">Cargar Imagen</label>

          <td>


              <br>
              <button   class="w-100 btn btn-lg btn-primary" (click)="eliminar(ex.id)"   type="submit">Eliminar Opcion</button>
              <br>
          <br>
          <button   class="w-100 btn btn-lg btn-primary" (click)="editar(ex.id)" type="submit">Editar Opcion</button>
          <br>
          <br>
          </td>
        </tr>


      </tbody>
    </table>
  </form>
