<div  style="text-align: center;" >

  <br>
  <h3>Estimado/a rut {{votanteRut}} continuacion vera sus papeletas </h3>
<br>
<table *ngIf="votacion.length>0" class="table">
  <thead>

     <tr>

         <th scope="col">Votaciones</th>
         <th scope="col">Votar</th>






     </tr>
       </thead>
        <tbody>

     <tr *ngFor="let ex of votacion">



         <td  >{{ex.nombre}}</td>
<td><a routerLink="/votar/{{votanteRut}}/{{ex.id}}" class="btn btn-primary">Votar</a> </td>

     </tr>

      </tbody>
 </table>

 <div *ngIf="votacion.length==0" style="text-align: center;">
  <p>Estimado/a no se encuentra ninguna papeleta</p>
 </div>

</div>
